// @material-ui/core components
import FormHelperText from "@material-ui/core/FormHelperText";
import withStyles from "@material-ui/core/styles/withStyles";
import {
	ADD_NEIGHBORHOOD_CHANNEL,
	DELETE_NEIGHBORHOOD_CHANNEL,
	REMOVE_NEIGHBORHOODMAP_IMAGE,
	REMOVE_NEIGHBORHOOD_IMAGE,
	SET_NEIGHBORHOODMAP_FILES_IMAGES,
	SET_NEIGHBORHOOD_CUSTOM_URI,
	SET_NEIGHBORHOOD_CUSTOM_URI_ERROR,
	SET_NEIGHBORHOOD_FILES_IMAGES,
	SET_NEIGHBORHOOD_NAME,
	SET_NEIGHBORHOOD_NAME_ERROR,
	SET_NEIGHBORHOOD_REGISTER_KEY
} from "actions/types";
import customInputStyle from "assets/jss/material-dashboard-pro-react/components/customInputStyle";
import customSelectStyle from "assets/jss/material-dashboard-pro-react/customSelectStyle";
import CustomInput from "components/CustomInput/CustomInput";
import ImageUpload from "components/CustomUpload/ImageUpload";
// core components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import PropTypes from "prop-types";
import { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { fetchSearchNeighborhoodCityChannels } from "actions/channel";

const ReactTags = require("react-tag-autocomplete");

const style = {
	infoText: {
		fontWeight: "300",
		margin: "10px 0 30px",
		textAlign: "center"
	},
	inputAdornmentIcon: {
		color: "#555"
	},
	inputAdornment: {
		position: "relative"
	},
	tags: {
		margin: "20px",
		width: "100%"
	},
	...customInputStyle,
	...customSelectStyle
};

class Step1 extends Component {
	sendState() {
		return this.state;
	}

	isValidated() {
		const { dispatch, name, customUri } = this.props;

		let success = true;
		if (!name || name.trim().length === 0) {
			dispatch({
				type: SET_NEIGHBORHOOD_NAME_ERROR
			});
			success = false;
		}
		if (!customUri || customUri.trim().length === 0) {
			dispatch({
				type: SET_NEIGHBORHOOD_CUSTOM_URI_ERROR
			});
			success = false;
		}
		return success;
	}

	onImageLoaded = (name, imagePreviewUrl) => {
		const { dispatch } = this.props;
		dispatch({
			type: SET_NEIGHBORHOOD_FILES_IMAGES,
			payload: [{ name, base64: imagePreviewUrl, out: "logoUri" }]
		});
	};

	onImageRemoved = () => {
		this.props.dispatch({
			type: REMOVE_NEIGHBORHOOD_IMAGE
		});
	};

	onNeighborhoodMapLoaded = (name, imagePreviewUrl) => {
		const { dispatch } = this.props;
		dispatch({
			type: SET_NEIGHBORHOODMAP_FILES_IMAGES,
			payload: [
				{
					name,
					base64: imagePreviewUrl,
					ignoreQualityTransform: true,
					out: "mapUri"
				},
				{ name: name + "-small", base64: imagePreviewUrl, out: "mapSmallUri" }
			]
		});
	};

	onNeighborhoodMapRemoved = () => {
		this.props.dispatch({
			type: REMOVE_NEIGHBORHOODMAP_IMAGE
		});
	};

	handleDelete = index => {
		const { dispatch } = this.props;

		if (index >= 0) {
			dispatch({
				type: DELETE_NEIGHBORHOOD_CHANNEL,
				index
			});
		}
	};

	handleAddition = channel => {
		const { dispatch } = this.props;
		dispatch({
			type: ADD_NEIGHBORHOOD_CHANNEL,
			value: channel
		});
	};

	onInput = query => {
		if (query?.length > 2) {
			this.props.fetchSearchNeighborhoodCityChannels(
				query,
				this.props.ignoreWeather
			);
		}
	};

	render() {
		const {
			classes,
			dispatch,
			name,
			nameErrorMsg,
			customUri,
			customUriErrorMsg,
			logoUri,
			mapSmallUri,
			registerkey,
			channels,
			channelsSuggestions
		} = this.props;

		return (
			<GridContainer justifyContent="center">
				<GridItem xs={12} sm={4} md={4}>
					<legend>Logo</legend>
					<ImageUpload
						ref={this.child}
						onImageLoaded={this.onImageLoaded}
						onImageRemoved={this.onImageRemoved}
						photoUri={logoUri}
						addButtonProps={{
							color: "brown",
							round: true
						}}
						changeButtonProps={{
							color: "brown",
							round: true
						}}
						removeButtonProps={{
							color: "danger",
							round: true
						}}
					/>
				</GridItem>
				<GridItem xs={12} sm={4} md={4}>
					<legend>Plan intéractif</legend>
					<ImageUpload
						ref={this.child}
						onImageLoaded={this.onNeighborhoodMapLoaded}
						onImageRemoved={this.onNeighborhoodMapRemoved}
						photoUri={mapSmallUri}
						addButtonProps={{
							color: "brown",
							round: true
						}}
						changeButtonProps={{
							color: "brown",
							round: true
						}}
						removeButtonProps={{
							color: "danger",
							round: true
						}}
					/>
				</GridItem>
				<GridItem xs={12} sm={12} md={12}>
					<CustomInput
						labelText="Nom *"
						id="name"
						formControlProps={{
							fullWidth: true
						}}
						error={nameErrorMsg !== null}
						helperText={nameErrorMsg}
						inputProps={{
							value: name ? name : "",
							onChange: e =>
								dispatch({
									type: SET_NEIGHBORHOOD_NAME,
									value: e.target.value
								}),
							autoComplete: "new-password"
						}}
					/>
				</GridItem>
				<GridItem xs={12} sm={12} md={12}>
					<CustomInput
						labelText="URI *"
						id="uri"
						formControlProps={{
							fullWidth: true
						}}
						error={customUriErrorMsg !== null}
						helperText={customUriErrorMsg}
						inputProps={{
							value: customUri ? customUri : "",
							onChange: e =>
								dispatch({
									type: SET_NEIGHBORHOOD_CUSTOM_URI,
									value: e.target.value
								})
						}}
					/>
				</GridItem>
				<GridItem xs={12} sm={12} md={12}>
					<CustomInput
						labelText="Clé pour rejoindre la communauté de voisinage"
						id="registerkey"
						formControlProps={{
							fullWidth: true
						}}
						inputProps={{
							value: registerkey ? registerkey : "",
							onChange: e =>
								dispatch({
									type: SET_NEIGHBORHOOD_REGISTER_KEY,
									value: e.target.value
								}),
							autoComplete: "new-password"
						}}
					/>
				</GridItem>
				<div className={classes.tags}>
					<FormHelperText>
						{
							"Canaux de diffusion depuis lesquelles recevoir publications, bons plans et météo"
						}
					</FormHelperText>
					<ReactTags
						placeholderText="Canaux de diffusion ..."
						tags={channels}
						suggestions={channelsSuggestions}
						minQueryLength={3}
						maxSuggestionsLength={10}
						onDelete={this.handleDelete}
						onAddition={this.handleAddition}
						suggestionsFilter={() => true}
						onInput={this.onInput}
						removeButtonText="Cliquez pour retirer"
						inputAttributes={{
							autoComplete: "new-password"
						}}
					/>
				</div>
			</GridContainer>
		);
	}
}

Step1.propTypes = {
	classes: PropTypes.object
};

const mapStateToProps = state => {
	const {
		name,
		nameErrorMsg,
		customUri,
		customUriErrorMsg,
		logoUri,
		mapSmallUri,
		registerkey,
		channels,
		channelsSuggestions,
		ignoreWeather
	} = state.neighborhoodReducer;
	return {
		name,
		nameErrorMsg,
		customUri,
		customUriErrorMsg,
		logoUri,
		mapSmallUri,
		registerkey,
		channels: Object.values(channels),
		channelsSuggestions: Object.values(channelsSuggestions),
		ignoreWeather
	};
};

const mapDispatchToProps = dispatch => {
	let actions = bindActionCreators(
		{
			fetchSearchNeighborhoodCityChannels
		},
		dispatch
	);
	return { ...actions, dispatch };
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withStyles(style)(Step1));
