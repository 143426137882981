import { Component } from "react";
import PropTypes from "prop-types";

import ReactTable from "react-table";

import { confirmAlert } from "react-confirm-alert";

import withStyles from "@material-ui/core/styles/withStyles";

// core components
import Wizard from "components/DigiHapi/Wizard";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Snackbar from "components/Snackbar/Snackbar";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardIcon from "components/Card/CardIcon";
import CardHeader from "components/Card/CardHeader";
import CardFooter from "components/Card/CardFooter";
import Button from "components/CustomButtons/Button";

// @material-ui/icons
import AddAlert from "@material-ui/icons/AddAlert";
import Edit from "@material-ui/icons/Edit";
import Delete from "@material-ui/icons/Delete";
import Add from "@material-ui/icons/Add";

import { GiBarracksTent } from "react-icons/gi";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import {
	fetchGetLocations,
	fetchAddLocation,
	fetchUpdateLocation,
	fetchDeleteLocation
} from "actions/location";

import extendedTablesStyle from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle";
import commonStyles from "assets/jss/DigiHapi/commonStyles";

import { beigeColor } from "assets/jss/material-dashboard-pro-react";

import Step1 from "./Step1";
import Step2 from "./Step2";

import {
	RESET_LOCATION,
	SET_LOCATION,
	SET_LOCATION_WIZARD_STEP,
	ADD_LOCATION,
	SET_LOCATION_NAME_FR,
	SET_LOCATION_NAME_EN,
	SET_LOCATION_ORDER
} from "actions/types.js";

import Components from "./LocationsPage";

const style = {
	...extendedTablesStyle,
	...commonStyles,
	customUI: {
		backgroundColor: beigeColor[1],
		borderRadius: "10px",
		padding: 10
	},
	descriptionContainer: {
		height: "60px"
	}
};

class LocationWizard extends Component {
	fetchAddLocation = () => {
		const { fetchAddLocation, name, lang, community } = this.props;

		fetchAddLocation({ name, lang, communityId: community._id });
	};

	fetchUpdateLocation = () => {
		const { fetchUpdateLocation, _id, name, lang, order } = this.props;

		fetchUpdateLocation({ _id, name, lang, order });
	};

	render() {
		const {
			update,
			isFetching,
			onClose,
			onChangeCurrentStep,
			wizardCurrentStep
		} = this.props;
		return (
			<GridItem xs={12} sm={12}>
				<Wizard
					ref={this._wizard}
					onClose={onClose}
					onChangeCurrentStep={onChangeCurrentStep}
					currentStep={wizardCurrentStep}
					isFetching={isFetching}
					validate
					steps={[
						{
							stepName: "Français",
							stepComponent: Step1,
							stepId: "french"
						},
						{
							stepName: "Anglais",
							stepComponent: Step2,
							stepId: "english"
						}
					]}
					title={
						update ? "Mise à jour d'un hébergement" : "Ajout d'un hébergement"
					}
					subtitle=""
					finishButtonClick={
						update ? this.fetchUpdateLocation : this.fetchAddLocation
					}
				/>
			</GridItem>
		);
	}
}

class LocationsPage extends Component {
	state = {
		br: false,
		color: "info",
		message: null,
		wizard: null
	};

	componentDidMount() {
		const { fetchGetLocations, newWizard, community } = this.props;
		fetchGetLocations(community._id);
		if (newWizard) {
			this.addWizard(newWizard === "update");
		}
	}

	componentDidUpdate(prevProps, prevState) {
		const {
			fetchGetLocations,
			snackErrorMsg,
			snackInfoMsg,
			newWizard,
			community
		} = this.props;

		if (snackErrorMsg && prevProps.snackErrorMsg !== snackErrorMsg) {
			this.autoCloseMessage();
		}

		if (snackInfoMsg && prevProps.snackInfoMsg !== snackInfoMsg) {
			this.setState({
				wizard: null
			});
			fetchGetLocations(community._id);
			this.autoCloseMessage();
		}

		if (newWizard && prevProps.newWizard !== newWizard) {
			this.addWizard(newWizard === "update");
		}
	}

	hideAlert = () => {
		this.setState({
			br: false
		});
	};

	autoCloseMessage = () => {
		const { snackErrorMsg, snackInfoMsg } = this.props;

		this.setState({
			br: true,
			message: snackInfoMsg ? snackInfoMsg : snackErrorMsg,
			color: snackInfoMsg ? "info" : "danger"
		});

		if (snackInfoMsg) {
			setTimeout(this.hideAlert, 5000);
		}
	};

	onClose = () => {
		const { fetchGetLocations, community } = this.props;
		this.setState({
			wizard: null
		});
		this.props.dispatch({
			type: RESET_LOCATION
		});
		fetchGetLocations(community._id);
	};

	onChangeCurrentStep = currentStep => {
		this.props.dispatch({
			type: SET_LOCATION_WIZARD_STEP,
			payload: currentStep
		});
	};

	addWizard = (update = true) => {
		this.setState({
			wizard: (
				<Components.LocationWizard
					update={update}
					onClose={this.onClose}
					onChangeCurrentStep={this.onChangeCurrentStep}
				/>
			)
		});
	};

	editLocation = (id, name, lang, order) => {
		const { dispatch } = this.props;
		dispatch({
			type: SET_LOCATION,
			payload: id
		});
		dispatch({
			type: SET_LOCATION_NAME_FR,
			payload: name
		});
		if (lang && lang.en) {
			dispatch({
				type: SET_LOCATION_NAME_EN,
				payload: lang.en.name
			});
		}
		dispatch({
			type: SET_LOCATION_ORDER,
			payload: order
		});
	};

	deleteLocation = id => {
		this.props.fetchDeleteLocation(id);
	};

	getRoundButtons = (id, name, lang, order) => {
		const { classes } = this.props;
		return [
			{ color: "brown", icon: Edit, onClick: this.editLocation },
			{ color: "danger", icon: Delete, onClick: this.delete }
		].map((prop, key) => {
			return (
				<Button
					round
					color={prop.color}
					className={classes.actionButton + " " + classes.actionButtonRound}
					disabled={this.state && this.state.wizard ? true : false}
					key={key}
					onClick={() => prop.onClick(id, name, lang, order)}
				>
					<prop.icon className={classes.icon} />
				</Button>
			);
		});
	};

	getLocations = () => {
		const { locations } = this.props;

		const locationsObj = locations.map(({ _id, name, lang, order }) => {
			return {
				name,
				actions: (
					<div className="actions-right">
						{this.getRoundButtons(_id, name, lang, order)}
					</div>
				)
			};
		});
		return locationsObj;
	};

	delete = locationId => {
		const { classes } = this.props;
		confirmAlert({
			customUI: ({ onClose }) => {
				return (
					<div className={classes.customUI}>
						<h2>Confirmation</h2>
						<p>Êtes-vous sûr de vouloir supprimer l'hébergement ?</p>
						<div className={classes.justifyContentSpaceAround}>
							<Button
								onClick={() => {
									onClose();
								}}
							>
								Annuler
							</Button>
							<Button
								color="danger"
								onClick={() => {
									this.deleteLocation(locationId);
									onClose();
								}}
							>
								Supprimer
							</Button>
						</div>
					</div>
				);
			}
		});
	};

	render() {
		const { color, message } = this.state;
		const { dispatch, classes, isFetchingList } = this.props;

		return (
			<GridContainer justifyContent="center">
				<GridItem xs={12}>
					<Card>
						<CardHeader icon>
							<CardIcon color="primary">
								<GiBarracksTent />
							</CardIcon>
							<h4 className={classes.cardIconTitle}>Hébergements</h4>
						</CardHeader>
						<CardBody>
							<ReactTable
								data={this.getLocations()}
								filterable
								columns={[
									{
										Header: "Nom",
										accessor: "name",
										sortable: true,
										filterable: false
									},
									{
										Header: "Actions",
										accessor: "actions",
										sortable: false,
										filterable: false
									}
								]}
								loading={isFetchingList}
								defaultPageSize={5}
								showPaginationTop
								showPaginationBottom={false}
								previousText="Précédent"
								nextText="Suivant"
								rowsText="Lignes"
								noDataText="Aucuns hébergements"
								ofText="sur"
								loadingText="Chargement..."
								className="-striped -highlight"
							/>
						</CardBody>
						<CardFooter product>
							<div className={classes.w100}>
								<Button
									color={"primary"}
									className={classes.floatRight}
									onClick={() =>
										dispatch({
											type: ADD_LOCATION
										})
									}
									disabled={this.state.wizard}
								>
									<Add /> {"Ajout d'un hébergement"}
								</Button>
							</div>
						</CardFooter>
					</Card>
				</GridItem>
				{this.state.wizard}
				<Snackbar
					place="br"
					color={color}
					icon={AddAlert}
					message={message ? message : ""}
					open={this.state.br}
					closeNotification={() => this.setState({ br: false })}
					close
				/>
			</GridContainer>
		);
	}
}

LocationsPage.propTypes = {
	classes: PropTypes.object
};

const mapStateToProps = state => {
	const {
		isFetchingList,
		isFetching,
		_id,
		locations,
		name,
		lang,
		order,
		error,
		nameError,
		snackInfoMsg,
		snackErrorMsg,
		newWizard,
		wizardCurrentStep
	} = state.locationReducer;

	const { community } = state.entitiesReducer;

	return {
		isFetchingList,
		isFetching,
		_id,
		locations: Object.values(locations),
		name,
		lang,
		order,
		error,
		nameError,
		snackInfoMsg,
		snackErrorMsg,
		newWizard,
		wizardCurrentStep,
		community
	};
};

const mapDispatchToProps = dispatch => {
	let actions = bindActionCreators(
		{
			fetchGetLocations,
			fetchAddLocation,
			fetchUpdateLocation,
			fetchDeleteLocation
		},
		dispatch
	);
	return { ...actions, dispatch };
};

export default {
	LocationsPage: connect(
		mapStateToProps,
		mapDispatchToProps
	)(withStyles(style)(LocationsPage)),
	LocationWizard: connect(
		mapStateToProps,
		mapDispatchToProps
	)(withStyles(style)(LocationWizard))
};
