import LinesEllipsis from "react-lines-ellipsis";

import ReactTable from "react-table";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Tooltip from "@material-ui/core/Tooltip";

import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css

// @material-ui/icons
import AddAlert from "@material-ui/icons/AddAlert";
import Delete from "@material-ui/icons/Delete";
// import Edit from "@material-ui/icons/Edit";
import PlaylistAdd from "@material-ui/icons/PlaylistAdd";
import ArtTrack from "@material-ui/icons/ArtTrack";
import Edit from "@material-ui/icons/Edit";
import ReportProblem from "@material-ui/icons/ReportProblem";
import Check from "@material-ui/icons/Check";
import RemoveRedEye from "@material-ui/icons/RemoveRedEye";
import Send from "@material-ui/icons/Send";
import CheckCircle from "@material-ui/icons/CheckCircle";
import Error from "@material-ui/icons/Error";

import {
	fetchGetOwnEvents,
	fetchAddEvent,
	fetchUpdateEvent,
	fetchPublishEvent,
	fetchGetEvent,
	fetchGetEventChannels,
	fetchDeleteEvent,
	fetchModeratingAccept,
	fetchModeratingRefuse
} from "actions/event";
import {
	RESET_EVENT,
	SET_POST_SORTED,
	EDITING,
	PUBLISHED,
	MODERATING,
	REFUSED,
	ROLE_WEBMASTER,
	ADD_WIZARD,
	CLEAR_SNACK_WARN_MSG
} from "actions/types";

import extendedTablesStyle from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle";
import dashboardStyle from "assets/jss/material-dashboard-pro-react/views/dashboardStyle";
import typographyStyle from "assets/jss/material-dashboard-pro-react/components/typographyStyle";
import commonStyles from "assets/jss/DigiHapi/commonStyles";

import { cla, flex, flex1, column } from "assets/jss/ocamping/commonStyle";

// core components
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import CardFooter from "components/Card/CardFooter";
import CardIcon from "components/Card/CardIcon";
import Button from "components/CustomButtons/Button";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Snackbar from "components/Snackbar/Snackbar";
import Wizard from "components/DigiHapi/Wizard";
import Activity from "components/DigiHapi/Activity";

import PropTypes from "prop-types";
import { Component, createRef, PureComponent, Fragment } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Components from "./PostsPage";
import { beigeColor } from "assets/jss/material-dashboard-pro-react";
import Pagination from "react-pagination-js";
import "react-pagination-js/dist/styles.css"; // import css

import Step1 from "./Step1";
import Step2 from "./Step2";
import Step3 from "./Step3";
import Step4 from "./Step4";
import Step5 from "./Step5";
import Step6 from "./Step6";
import Step7 from "./Step7";

import canUseDOM from "can-use-dom";

const style = {
	...dashboardStyle,
	...extendedTablesStyle,
	...typographyStyle,
	...commonStyles,
	customUI: {
		backgroundColor: beigeColor[1],
		borderRadius: "10px",
		padding: 10
	},
	descriptionContainer: {
		height: "60px"
	},
	preWrap: {
		whiteSpace: "pre-wrap"
	}
};

const geolocation =
	canUseDOM && navigator.geolocation
		? navigator.geolocation
		: {
				getCurrentPosition(success, failure) {
					failure("Your browser doesn't support geolocation.");
				}
		  };

class PostsWizard extends Component {
	fetchAddEvent = () => {
		const {
			fetchAddEvent,
			title,
			description,
			url,
			startDate,
			files,
			lang,
			goodDealId,
			photoUri,
			eventTypeId,
			promotion
		} = this.props;

		fetchAddEvent({
			title,
			description,
			url,
			startDate: startDate ? startDate.toDate().getTime() : null,
			files,
			lang,
			goodDealId,
			photoUri,
			eventTypeId,
			promotion
		});
	};

	fetchUpdateEvent = () => {
		const {
			fetchUpdateEvent,
			_id,
			title,
			description,
			url,
			startDate,
			files,
			lang,
			goodDealId,
			photoUri,
			eventTypeId,
			promotion
		} = this.props;

		fetchUpdateEvent({
			_id,
			title,
			description,
			url,
			startDate: startDate ? startDate.toDate().getTime() : null,
			files,
			lang,
			goodDealId,
			photoUri,
			eventTypeId,
			promotion
		});
	};

	render() {
		const { update, isFetching, onClose } = this.props;
		return (
			<GridItem xs={12} sm={12}>
				<Wizard
					onClose={onClose}
					isFetching={isFetching}
					validate
					steps={[
						{
							stepName: "Type",
							stepComponent: Step1,
							stepId: "link"
						},
						{
							stepName: "Illustration",
							stepComponent: Step2,
							stepId: "picture"
						},
						{
							stepName: "Français",
							stepComponent: Step3,
							stepId: "french"
						},
						{
							stepName: "Anglais",
							stepComponent: Step4,
							stepId: "english"
						}
					]}
					title={
						update
							? "Mise à jour d'une publication : "
							: "Ajout d'une publication"
					}
					subtitle=""
					finishButtonClick={
						update ? this.fetchUpdateEvent : this.fetchAddEvent
					}
					finishButtonText={update ? "Modifier" : "Ajouter"}
				/>
			</GridItem>
		);
	}
}

class PublishWizard extends Component {
	publishEvent = () => {
		const { fetchPublishEvent, _id: eventId, channelsIds } = this.props;
		fetchPublishEvent(eventId, channelsIds);
	};

	render() {
		const { isFetching, onClose } = this.props;
		return (
			<GridItem xs={12} sm={12}>
				<Wizard
					onClose={onClose}
					isFetching={isFetching}
					validate
					steps={[
						{
							stepName: "HapiCity",
							stepComponent: Step5,
							stepId: "hapicity_channels"
						},
						{
							stepName: "O’Camping",
							stepComponent: Step6,
							stepId: "ocamping_channels"
						},
						{
							stepName: "HapiColibri",
							stepComponent: Step7,
							stepId: "hapicolibri_channels"
						}
					]}
					title={"Mise en ligne d'une publication"}
					subtitle=""
					finishButtonClick={this.publishEvent}
					finishButtonText={"Publier"}
				/>
			</GridItem>
		);
	}
}

class PostsPage extends PureComponent {
	state = {
		br: false,
		color: "info",
		message: null,
		wizard: null,
		modeTable: false
	};

	constructor(props) {
		super(props);
		this.wizardRef = createRef();
		this.wizardPublishRef = createRef();
	}

	componentDidMount() {
		this.props.fetchGetOwnEvents();
	}

	componentDidUpdate(prevProps, prevState) {
		const {
			dispatch,
			snackErrorMsg,
			snackWarnMsg,
			snackInfoMsg,
			newWizard,
			fetchGetOwnEvents,
			warnMsgCleared
		} = this.props;

		if (warnMsgCleared && !prevProps.warnMsgCleared) {
			dispatch({
				type: ADD_WIZARD
			});
		}
		if (
			(snackErrorMsg && prevProps.snackErrorMsg !== snackErrorMsg) ||
			(snackWarnMsg && prevProps.snackWarnMsg !== snackWarnMsg)
		) {
			this.autoCloseMessage();
		}

		if (snackInfoMsg && prevProps.snackInfoMsg !== snackInfoMsg) {
			this.setState({
				wizard: null
			});
			fetchGetOwnEvents();
			this.autoCloseMessage();
		}

		if (newWizard && prevProps.newWizard !== newWizard) {
			if (newWizard === "publish") {
				this.addPublishWizard();
			} else {
				this.addWizard(newWizard === "update");
			}
		}
	}

	hideAlert = () => {
		this.setState({
			br: false
		});
	};

	autoCloseMessage = () => {
		const { snackErrorMsg, snackWarnMsg, snackInfoMsg } = this.props;

		this.setState({
			br: true,
			message: snackInfoMsg
				? snackInfoMsg
				: snackWarnMsg
				? snackWarnMsg
				: snackErrorMsg,
			color: snackInfoMsg ? "info" : snackWarnMsg ? "warning" : "danger"
		});

		if (snackInfoMsg) {
			setTimeout(this.hideAlert, 5000);
		}
	};

	onClose = () => {
		this.setState({
			wizard: null
		});
		this.props.dispatch({
			type: RESET_EVENT
		});
	};

	addWizard = (update = true) => {
		const { classes } = this.props;
		this.setState(
			{
				wizard: (
					// wrap the React component in a standard DOM element and assign a ref to that
					// to scroll correctly
					<div
						className={classes.w100}
						ref={elt => {
							this.wizardRef = elt;
						}}
					>
						<Components.PostsWizard update={update} onClose={this.onClose} />
					</div>
				)
			},
			() => this.wizardRef.scrollIntoView({ behavior: "smooth" })
		);
	};

	addPublishWizard = eventId => {
		const { classes } = this.props;
		this.setState(
			{
				wizard: (
					// wrap the React component in a standard DOM element and assign a ref to that
					// to scroll correctly
					<div
						className={classes.w100}
						ref={elt => {
							this.wizardPublishRef = elt;
						}}
					>
						<Components.PublishWizard
							eventId={eventId}
							onClose={this.onClose}
						/>
					</div>
				)
			},
			// () => console.log("yes")
			() => this.wizardPublishRef.scrollIntoView({ behavior: "smooth" })
		);
	};

	get2Chars = param => {
		return ("" + param).length === 1 ? "0" + param : param;
	};

	formatDate = d => {
		const date = new Date(d);
		return `${this.get2Chars(date.getDate())}/${this.get2Chars(
			date.getMonth() + 1
		)}/${date.getFullYear()} ${this.get2Chars(
			date.getHours()
		)}:${this.get2Chars(date.getMinutes())}`;
	};

	deleteEvent = id => {
		this.props.fetchDeleteEvent(id);
	};

	acceptEvent = id => {
		this.props.fetchModeratingAccept(id);
	};

	refuseEvent = id => {
		this.props.fetchModeratingRefuse(id);
	};

	getRoundButtons = (id, status) => {
		const { classes, user } = this.props;
		const actions = [];

		if (status === EDITING || status === PUBLISHED) {
			actions.push({
				color: "success",
				icon: Send,
				onClick: this.editEventChannels,
				tooltip: "Publier"
			});
		}
		if (status === MODERATING && user.role === ROLE_WEBMASTER) {
			actions.push({
				color: "danger",
				icon: Error,
				onClick: this.refuseEvent,
				tooltip: "Rejeter"
			});
		}
		if (status === MODERATING && user.role === ROLE_WEBMASTER) {
			actions.push({
				color: "success",
				icon: CheckCircle,
				onClick: this.acceptEvent,
				tooltip: "Accepter"
			});
		}
		if (status !== MODERATING || user.role === ROLE_WEBMASTER) {
			actions.push({
				color: "brown",
				icon: Edit,
				onClick: this.editEvent,
				tooltip: "Modifier"
			});
		}
		actions.push({
			color: "danger",
			icon: Delete,
			onClick: this.delete,
			tooltip: "Supprimer"
		});
		return actions.map((prop, key) => {
			return (
				<Tooltip
					key={key}
					title={prop.tooltip}
					placement="bottom"
					classes={{ tooltip: classes.tooltip }}
				>
					<Button
						round
						color={prop.color}
						className={classes.actionButton + " " + classes.actionButtonRound}
						disabled={this.state && this.state.wizard ? true : false}
						onClick={() => prop.onClick(id)}
					>
						<prop.icon className={classes.icon} />
					</Button>
				</Tooltip>
			);
		});
	};

	getStrStatus = status => {
		switch (status) {
			case MODERATING:
				return "En cours de modération";

			case PUBLISHED:
				return "Publiée";

			case REFUSED:
				return "Rejetée";

			default:
				return "Brouillon";
		}
	};

	getEventsData = () => {
		const { events, classes } = this.props;

		const ownEvents = events.map(
			({ _id, dateUpdated, description, title, status }) => {
				return {
					dateUpdated: this.formatDate(dateUpdated),
					description: (
						<LinesEllipsis className={classes.preWrap} text={description} />
					),
					title,
					status: this.getStrStatus(status),
					actions: (
						<div className="actions-right">
							{this.getRoundButtons(_id, status)}
						</div>
					)
				};
			}
		);
		return ownEvents;
	};

	delete = eventId => {
		const { classes } = this.props;
		confirmAlert({
			customUI: ({ onClose }) => {
				return (
					<div className={classes.customUI}>
						<h2>Confirmation</h2>
						<p>Êtes-vous sûr de vouloir supprimer la publication ?</p>
						<div className={classes.justifyContentSpaceAround}>
							<Button
								onClick={() => {
									onClose();
								}}
							>
								Annuler
							</Button>
							<Button
								color="danger"
								onClick={() => {
									this.deleteEvent(eventId);
									onClose();
								}}
							>
								Supprimer
							</Button>
						</div>
					</div>
				);
			}
		});
	};

	editEvent = id => {
		this.props.fetchGetEvent(id);
	};

	editEventChannels = id => {
		this.props.fetchGetEventChannels(id);
	};

	changeCurrentPage = page => {
		const { fetchGetOwnEvents, limit, sorted } = this.props;
		fetchGetOwnEvents(page, limit, sorted);
	};

	render() {
		const { color, message, wizard, modeTable } = this.state;
		const {
			dispatch,
			classes,
			isFetchingList,
			page,
			totalPages,
			totalDocs,
			limit,
			sorted,
			fetchGetOwnEvents,
			events,
			user,
			history
		} = this.props;

		return isFetchingList ? (
			<Activity />
		) : (
			<GridContainer className={cla(classes, [flex, flex1])}>
				{!modeTable && (
					<Fragment>
						{events.map((event, index) => (
							<GridItem
								key={event._id}
								xs={12}
								sm={12}
								md={4}
								className={cla(classes, [flex])}
							>
								<Card
									className={classes.cardHover}
									product
									data-aos-duration={1000 + (index % 3) * 300}
								>
									{event.photoUri ? (
										<CardHeader
											image
											className={wizard ? "" : classes.cardHeaderHover}
										>
											{event.status === EDITING ? (
												<a
													href={`https://app.ocamping.fr/event?id=${event._id}`}
													target="_blank"
													rel="noopener noreferrer"
												>
													<img src={event.photoUri} alt={event.description} />
												</a>
											) : (
												<img src={event.photoUri} alt={event.description} />
											)}
										</CardHeader>
									) : (
										<CardHeader
											picto
											className={wizard ? "" : classes.cardHeaderHover}
										>
											<a href="#pablo" onClick={e => e.preventDefault()}>
												<img
													src={require("assets/img/tent_placeholder.png")}
													alt={event.description}
												/>
											</a>
										</CardHeader>
									)}
									<CardBody className={cla(classes, [flex, flex1, column])}>
										<div className={classes.cardHoverUnder}>
											{(event.status === EDITING ||
												event.status === PUBLISHED) && (
												<Tooltip
													title="Publier"
													placement="bottom"
													classes={{ tooltip: classes.tooltip }}
												>
													<Button
														color="success"
														simple
														justIcon
														onClick={() => this.editEventChannels(event._id)}
													>
														<Send className={classes.underChartIcons} />
													</Button>
												</Tooltip>
											)}
											{event.status === MODERATING &&
												user.role === ROLE_WEBMASTER && (
													<Tooltip
														title="Rejeter"
														placement="bottom"
														classes={{ tooltip: classes.tooltip }}
													>
														<Button
															color="danger"
															simple
															justIcon
															onClick={() => this.refuseEvent(event._id)}
														>
															<Error className={classes.underChartIcons} />
														</Button>
													</Tooltip>
												)}
											{event.status === MODERATING &&
												user.role === ROLE_WEBMASTER && (
													<Tooltip
														title="Accepter"
														placement="bottom"
														classes={{ tooltip: classes.tooltip }}
													>
														<Button
															color="success"
															simple
															justIcon
															onClick={() => this.acceptEvent(event._id)}
														>
															<CheckCircle
																className={classes.underChartIcons}
															/>
														</Button>
													</Tooltip>
												)}
											{(event.status !== MODERATING ||
												user.role === ROLE_WEBMASTER) && (
												<Tooltip
													title="Modifier"
													placement="bottom"
													classes={{ tooltip: classes.tooltip }}
												>
													<Button
														color="brown"
														simple
														justIcon
														onClick={() => this.editEvent(event._id)}
													>
														<Edit className={classes.underChartIcons} />
													</Button>
												</Tooltip>
											)}
											<Tooltip
												title="Supprimer"
												placement="bottom"
												classes={{ tooltip: classes.tooltip }}
											>
												<Button
													color="danger"
													simple
													justIcon
													onClick={() => this.delete(event._id)}
												>
													<Delete className={classes.underChartIcons} />
												</Button>
											</Tooltip>
										</div>
										<h3 className={classes.cardProductTitle}>
											<a
												href={`https://app.ocamping.fr/event?id=${event._id}`}
												target="_blank"
												rel="noopener noreferrer"
											>
												{event.title
													? event.title
													: `Publication du ${this.formatDate(
															event.dateUpdated
													  )}`}
											</a>
										</h3>
										<div className={classes.descriptionContainer}>
											<LinesEllipsis
												className={
													classes.cardProductDesciprion + " " + classes.preWrap
												}
												text={event.description}
												maxLine={3}
												trimRight
												basedOn="letters"
											/>
										</div>
									</CardBody>
									<CardFooter product>
										<div />
										{event.status === EDITING && (
											<div
												className={`${classes.stats} ${classes.productStats} ${classes.infoText} ${classes.floatRight}`}
											>
												<Edit /> {" Brouillon"}
											</div>
										)}
										{event.status === MODERATING && (
											<div
												className={`${classes.stats} ${classes.productStats} ${classes.warningText} ${classes.floatRight}`}
											>
												<RemoveRedEye /> {" En cours de modération"}
											</div>
										)}
										{event.status === REFUSED && (
											<div
												className={`${classes.stats} ${classes.productStats} ${classes.dangerText} ${classes.floatRight}`}
											>
												<ReportProblem /> {" Rejetée"}
											</div>
										)}
										{event.status === PUBLISHED && (
											<div
												className={`${classes.stats} ${classes.productStats} ${classes.successText} ${classes.floatRight}`}
											>
												<Check /> {" Publiée"}
											</div>
										)}
									</CardFooter>
								</Card>
							</GridItem>
						))}
						{!wizard && (
							<GridContainer>
								<GridItem xs={12} sm={12} md={8}>
									<div className={classes.floatRight}>
										<Pagination
											currentPage={page}
											totalSize={totalDocs}
											sizePerPage={limit}
											totalPages={totalPages}
											theme="bootstrap"
											changeCurrentPage={this.changeCurrentPage}
										/>
									</div>
								</GridItem>
								<GridItem xs={12} sm={12} md={4}>
									<Button
										color={"primary"}
										className={classes.floatRight}
										onClick={() =>
											dispatch({
												type: CLEAR_SNACK_WARN_MSG
											})
										}
									>
										<PlaylistAdd /> {"Ajout d'une publication"}
									</Button>
									<Button
										color={"rose"}
										className={classes.floatRight}
										onClick={() => this.setState({ modeTable: !modeTable })}
									>
										<ArtTrack />
										{" Tableau"}
									</Button>
								</GridItem>
							</GridContainer>
						)}
					</Fragment>
				)}
				{modeTable && (
					<GridItem xs={12}>
						<Card>
							<CardHeader icon>
								<CardIcon color="primary">
									<PlaylistAdd />
								</CardIcon>
								<h4 className={classes.cardIconTitle}>Publications</h4>
							</CardHeader>
							<CardBody>
								<ReactTable
									data={this.getEventsData()}
									filterable
									columns={[
										{
											Header: "Date",
											accessor: "dateUpdated",
											sortable: true,
											filterable: false
										},
										{
											Header: "Titre",
											accessor: "title",
											sortable: true,
											filterable: false
										},
										{
											Header: "Description",
											accessor: "description",
											sortable: false,
											filterable: false
										},
										{
											Header: "Statut",
											accessor: "status",
											sortable: true,
											filterable: false
										},
										{
											Header: "Actions",
											accessor: "actions",
											sortable: false,
											filterable: false
										}
									]}
									loading={isFetchingList}
									manual
									onFetchData={(state, instance) => {
										dispatch({
											type: SET_POST_SORTED,
											value: state.sorted
										});
										fetchGetOwnEvents(
											state.page + 1,
											state.pageSize,
											state.sorted
										);
									}}
									page={page - 1}
									defaultPageSize={limit}
									defaultSorted={sorted}
									pageSizeOptions={[3, 6, 9, 18, 36, 72]}
									pages={totalPages}
									showPaginationTop
									showPaginationBottom={false}
									previousText="Précédent"
									nextText="Suivant"
									rowsText="Lignes"
									noDataText="Aucunes publications"
									ofText="sur"
									loadingText="Chargement..."
									className="-striped -highlight"
								/>
							</CardBody>
							<CardFooter product>
								<div className={classes.w100}>
									<Button
										color={"primary"}
										className={classes.floatRight}
										onClick={() =>
											dispatch({
												type: CLEAR_SNACK_WARN_MSG
											})
										}
										disabled={wizard}
									>
										<PlaylistAdd /> {"Ajout d'une publication"}
									</Button>
									<Button
										color={"rose"}
										className={classes.floatRight}
										onClick={() => this.setState({ modeTable: !modeTable })}
										disabled={wizard}
									>
										<ArtTrack />
										{" Fiches"}
									</Button>
								</div>
							</CardFooter>
						</Card>
					</GridItem>
				)}
				{this.state.wizard}
				<Snackbar
					place="br"
					color={color}
					icon={AddAlert}
					message={message ? message : ""}
					open={this.state.br}
					closeNotification={() => {
						this.setState({ br: false });
						if (color === "warning") {
							history.push("/team/profile");
						}
					}}
					close
				/>
			</GridContainer>
		);
	}
}

PostsPage.propTypes = {
	classes: PropTypes.object
};

const mapStateToProps = state => {
	const {
		_id,
		title,
		description,
		url,
		startDate,
		files,
		lang,
		photoUri,
		campsitesIds,
		citiesIds,
		snackErrorMsg,
		snackWarnMsg,
		snackInfoMsg,
		events,
		goodDealId,
		isFetching,
		isFetchingList,
		page,
		hasNextPage,
		nextPage,
		totalPages,
		totalDocs,
		limit,
		sorted,
		newWizard,
		eventTypeId,
		promotion,
		warnMsgCleared,
		channelsIds
	} = state.eventReducer;

	const { user } = state.profileReducer;

	return {
		isFetching,
		_id,
		title,
		description,
		url,
		startDate,
		files,
		lang,
		photoUri,
		campsitesIds,
		citiesIds,
		snackErrorMsg,
		snackWarnMsg,
		snackInfoMsg,
		events: Object.values(events),
		isFetchingList,
		goodDealId,
		page,
		hasNextPage,
		nextPage,
		totalPages,
		totalDocs,
		limit,
		sorted,
		newWizard,
		user,
		eventTypeId,
		promotion,
		warnMsgCleared,
		channelsIds
	};
};

const mapDispatchToProps = dispatch => {
	let actions = bindActionCreators(
		{
			fetchGetOwnEvents,
			fetchAddEvent,
			fetchGetEventChannels,
			fetchUpdateEvent,
			fetchPublishEvent,
			fetchModeratingAccept,
			fetchModeratingRefuse,
			fetchGetEvent,
			fetchDeleteEvent
		},
		dispatch
	);
	return { ...actions, dispatch };
};

export default {
	PostsPage: connect(
		mapStateToProps,
		mapDispatchToProps
	)(withStyles(style)(PostsPage)),
	PostsWizard: connect(
		mapStateToProps,
		mapDispatchToProps
	)(withStyles(style)(PostsWizard)),
	PublishWizard: connect(
		mapStateToProps,
		mapDispatchToProps
	)(withStyles(style)(PublishWizard))
};
