import merge from "lodash/merge";
import {
	RESET_DATA,
	SET_AUDITS,
	SET_CAMPSITES_NAMES,
	SET_CAMPSITE_CHANNELS,
	SET_CAMPSITE_DETAILS_RESULT,
	SET_CITIES_NAMES,
	SET_CITIES_TAGS,
	SET_CITY_CHANNELS,
	SET_CITY_DETAILS_RESULT,
	SET_COMMUNITY_DETAILS_RESULT,
	SET_CURRENT_CAMPSITE_DETAILS_RESULT,
	SET_CURRENT_COMMUNITY_DETAILS_RESULT,
	SET_EQUIPMENTS_RESULT,
	SET_GOODDEAL_CAMPSITE_CHANNELS,
	SET_GOODDEAL_CAMPSITE_CITY_CHANNELS,
	SET_GOODDEAL_CITY_CHANNELS,
	SET_GOODDEAL_NEIGHBORHOOD_CHANNELS,
	SET_GOODDEAL_NEIGHBORHOOD_CITY_CHANNELS,
	SET_GOOD_DEAL_CHANNELS_RESULT,
	SET_LIVE_EVENTS,
	SET_NEAR_CITIES,
	SET_NEIGHBORHOODS_NAMES,
	SET_NEIGHBORHOOD_CHANNELS,
	SET_NEIGHBORHOOD_DETAILS_RESULT,
	SET_OWN_EVENTS_RESULT,
	SET_OWN_GOOD_DEAL_RESULT,
	SET_TEMPLATES_DATES_RESULT,
	SET_TEMPLATES_RESULT
} from "../actions/types";

const initialState = {
	locations: {},
	templates: {},
	templateTimes: {},
	categories: {},
	goodDealsCategories: {},
	hobbies: {},
	eventTypes: {},
	community: {}
};

export default function entities(state = initialState, action) {
	switch (action.type) {
		case RESET_DATA:
			return {
				...initialState,
				eventTypes: state.eventTypes
			};

		case SET_CURRENT_COMMUNITY_DETAILS_RESULT:
		case SET_COMMUNITY_DETAILS_RESULT:
			if (
				action.entities.community &&
				action.entities.community &&
				Object.values(action.entities.community).length > 0
			) {
				return {
					...state,
					community: Object.values(action.entities.community)[0]
				};
			}
			break;

		case SET_NEAR_CITIES:
		case SET_NEIGHBORHOODS_NAMES:
		case SET_CAMPSITES_NAMES:
		case SET_CITIES_NAMES:
		case SET_CITY_CHANNELS:
		case SET_CAMPSITE_CHANNELS:
		case SET_NEIGHBORHOOD_CHANNELS:
		case SET_OWN_GOOD_DEAL_RESULT:
		case SET_LIVE_EVENTS:
		case SET_EQUIPMENTS_RESULT:
		case SET_AUDITS:
		case SET_TEMPLATES_RESULT:
		case SET_TEMPLATES_DATES_RESULT:
		case SET_CURRENT_CAMPSITE_DETAILS_RESULT:
		case SET_OWN_EVENTS_RESULT:
		case SET_CITIES_TAGS:
		case SET_GOODDEAL_CITY_CHANNELS:
		case SET_GOOD_DEAL_CHANNELS_RESULT:
		case SET_GOODDEAL_CAMPSITE_CITY_CHANNELS:
		case SET_GOODDEAL_CAMPSITE_CHANNELS:
		case SET_GOODDEAL_NEIGHBORHOOD_CITY_CHANNELS:
		case SET_GOODDEAL_NEIGHBORHOOD_CHANNELS:
		case SET_CITY_CHANNELS:
		case SET_CITY_DETAILS_RESULT:
		case SET_CAMPSITE_DETAILS_RESULT:
		case SET_NEIGHBORHOOD_DETAILS_RESULT:
			break;

		default:
			if (action.entities) {
				return merge({}, state, action.entities);
			}
			break;
	}
	return state;
}
