const initialState = {
	pathname: null
};

export default function navReducer(state = initialState, action = {}) {
	switch (action.type) {
		case "@@router/LOCATION_CHANGE":
			return {
				...state,
				pathname: action.payload?.location?.pathname,
				...action.payload
			};

		default:
			return state;
	}
}
