import { apiAction } from "./api";
import {
	FETCH_GET_GLOBAL_INFORMATIONS_VERSION,
	FETCH_PUBLISH_EVENT,
	FETCH_UPLOAD_IMAGES,
	SET_GLOBAL_INFORMATIONS_VERSIONS,
	SET_GLOBAL_INFORMATIONS_VERSIONS_FAILURE,
	SET_PUBLISH_EVENT_FAILURE,
	SET_PUBLISH_EVENT_RESULT,
	SET_UPLOAD_IMAGES_FAILURE,
	SET_UPLOAD_IMAGES_RESULT,
	FETCH_GET_CACHED_IMAGES,
	SET_CACHED_IMAGES,
	SET_CACHED_IMAGES_FAILURE,
	FETCH_DELETE_IMAGE,
	SET_DELETE_IMAGE_RESULT,
	SET_DELETE_IMAGE_FAILURE,
	FETCH_GET_PICTOS_URI,
	SET_PICTOS_URI,
	SET_PICTOS_URI_FAILURE
} from "./types";

export function fetchGetGlobalInformationsVersions() {
	return apiAction({
		url: "/globalinformations/versions",
		method: "GET",
		onSuccess: setGlobalInformationsVersions,
		onFailure: setGlobalInformationsVersionsFailure,
		label: FETCH_GET_GLOBAL_INFORMATIONS_VERSION
	});
}

function setGlobalInformationsVersions(data) {
	return {
		type: SET_GLOBAL_INFORMATIONS_VERSIONS,
		payload: data
	};
}

function setGlobalInformationsVersionsFailure(error) {
	return {
		type: SET_GLOBAL_INFORMATIONS_VERSIONS_FAILURE,
		payload: error
	};
}

export function fetchPublishEvent(description, url, startDate, files, lang) {
	return apiAction({
		url: "/events/publish",
		method: "POST",
		data: {
			description,
			url,
			startDate: startDate ? startDate.toDate().getTime() : null,
			files,
			lang
		},
		onSuccess: setPublishEventResult,
		onFailure: setPublishEventFaliure,
		label: FETCH_PUBLISH_EVENT
	});
}

function setPublishEventResult(data) {
	return {
		type: SET_PUBLISH_EVENT_RESULT,
		payload: data
	};
}

function setPublishEventFaliure(error) {
	return {
		type: SET_PUBLISH_EVENT_FAILURE,
		payload: error
	};
}

const TYPE_SHARING = 1;
const TYPE_HAPICOLIBRI_SHARING = 2;
// const TYPE_OCAMPING_HOME = 3;
// const TYPE_HAPICOLIBRI_HOME = 4;
const TYPE_AD_CATEGORY = 5;
// const TYPE_METEO = 6;
// const TYPE_OTHERS = 7;

export function fetchGetPictosUri() {
	return apiAction({
		url: "/globalinformations/cachedImages",
		method: "GET",
		data: {
			types: [TYPE_SHARING, TYPE_HAPICOLIBRI_SHARING, TYPE_AD_CATEGORY]
		},
		onSuccess: setPictosUri,
		onFailure: setPictosUriFailure,
		label: FETCH_GET_PICTOS_URI,
		withCredentials: false
	});
}

function setPictosUri(data) {
	return {
		type: SET_PICTOS_URI,
		payload: data
	};
}

function setPictosUriFailure(error) {
	return {
		type: SET_PICTOS_URI_FAILURE,
		payload: error
	};
}

export function fetchGetCachedImages() {
	return apiAction({
		url: "/globalinformations/imgs",
		method: "GET",
		onSuccess: setCachedImages,
		onFailure: setCachedImagesFailure,
		label: FETCH_GET_CACHED_IMAGES
	});
}

function setCachedImages(data) {
	return {
		type: SET_CACHED_IMAGES,
		payload: data
	};
}

function setCachedImagesFailure(error) {
	return {
		type: SET_CACHED_IMAGES_FAILURE,
		payload: error
	};
}

export function fetchStoreCachedImage(data) {
	return apiAction({
		url: "/globalinformations/cachedImage",
		method: "POST",
		data,
		onSuccess: setStoreCachedImageResult,
		onFailure: setStoreCachedImageFailure,
		label: FETCH_UPLOAD_IMAGES
	});
}

function setStoreCachedImageResult(data) {
	return {
		type: SET_UPLOAD_IMAGES_RESULT,
		payload: data
	};
}

function setStoreCachedImageFailure(error) {
	return {
		type: SET_UPLOAD_IMAGES_FAILURE,
		payload: error
	};
}

export function fetchDeleteImage(id) {
	return apiAction({
		url: `/globalinformations/cachedImage/${id}`,
		method: "DELETE",
		onSuccess: setDeleteImageResult,
		onFailure: setDeleteImageFailure,
		label: FETCH_DELETE_IMAGE
	});
}

function setDeleteImageResult(data) {
	return {
		type: SET_DELETE_IMAGE_RESULT,
		payload: data
	};
}

function setDeleteImageFailure(error) {
	return {
		type: SET_DELETE_IMAGE_FAILURE,
		payload: error
	};
}
