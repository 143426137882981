import {
	whiteColor,
	beigeColor,
	primaryColor,
	blueColor,
	roseColor,
	brownColor,
	darkGrayColor,
	grayColor
} from "assets/jss/material-dashboard-pro-react.js";

const commonStyles = {
	colorPrimary: {
		color: primaryColor[0]
	},
	blue: {
		color: blueColor[0] + "!important"
	},
	brown: {
		color: brownColor[0]
	},
	rose: {
		color: roseColor[0]
	},
	red: {
		color: "red"
	},
	lightblue: {
		color: "#b4d6df"
	},
	lightgrey: {
		color: "#f1f1f1"
	},
	white: {
		color: "white"
	},
	darkgrey: {
		color: darkGrayColor
	},
	/* Background color */
	bgTransparent: {
		backgroundColor: "transparent"
	},
	bgPrimary: {
		backgroundColor: primaryColor[0]
	},
	bgGray: {
		backgroundColor: grayColor[15]
	},
	bgBeige: {
		backgroundColor: beigeColor[0]
	},
	bgWhite: {
		backgroundColor: whiteColor
	},
	bgBlack: {
		backgroundColor: "black"
	},
	bgDarkgrey: {
		backgroundColor: darkGrayColor
	},
	/* Flex */
	row: {
		flexDirection: "row"
	},
	column: {
		flexDirection: "column"
	},
	rowReverse: {
		flexDirection: "row-reverse"
	},
	columnReverse: {
		flexDirection: "column-reverse"
	},
	flex: {
		display: "flex"
	},
	flex0: {
		flex: 0
	},
	flex1: {
		flex: 1
	},
	flex2: {
		flex: 2
	},
	flexWrap: {
		flexWrap: "wrap"
	},
	spaceAround: {
		justifyContent: "space-around"
	},
	spaceBetween: {
		justifyContent: "space-between"
	},
	justifyContentCenter: {
		justifyContent: "center"
	},
	justifyContentFlexEnd: {
		justifyContent: "flex-end"
	},
	justifyContentSpaceEvenly: {
		justifyContent: "space-evenly"
	},
	justifyContentSpaceAround: {
		justifyContent: "space-around"
	},
	justifyContentSpaceBetween: {
		justifyContent: "space-between"
	},
	alignItemsCenter: {
		alignItems: "center"
	},
	alignItemsSpaceBetween: {
		alignItems: "space-between"
	},
	alignItemsFlexEnd: {
		alignItems: "flex-end"
	},
	/* Margin */
	m10: {
		margin: 10
	},
	mr5: {
		marginRight: 5
	},
	mt5: {
		marginTop: 5
	},
	mt10: {
		marginTop: 10
	},
	mt20: {
		marginTop: 20
	},
	ml5: {
		marginLeft: 5
	},
	ml10: {
		marginLeft: 10
	},
	ml20: {
		marginLeft: 20
	},
	mr10: {
		marginRight: 10
	},
	mb10: {
		marginBottom: 10
	},
	mb5: {
		marginBottom: 5
	},
	mb20: {
		marginBottom: 20
	},
	mb40: {
		marginBottom: 40
	},
	mv10: {
		marginVertical: 10
	},
	mv20: {
		marginVertical: 20
	},
	mv5: {
		marginVertical: 5
	},
	mh10: {
		marginHorizontal: 10
	},
	/* Padding */
	p5: {
		padding: 5
	},
	p10: {
		padding: 10
	},
	p20: {
		padding: 20
	},
	p40: {
		padding: 40
	},
	pt10: {
		paddingTop: 10
	},
	pb10: {
		paddingBottom: 10
	},
	pb20: {
		paddingBottom: 20
	},
	pb50: {
		paddingBottom: 50
	},
	pr5: {
		paddingRight: 5
	},
	pr10: {
		paddingRight: 10
	},
	pl20: {
		paddingLeft: 20
	},
	pv10: {
		paddingVertical: 10
	},
	pv20: {
		paddingVertical: 20
	},
	ph10: {
		paddingHorizontal: 10
	},
	ph20: {
		paddingHorizontal: 20
	},
	ph40: {
		paddingHorizontal: 40
	},
	/* Fonts */
	font: {
		fontFamily: "font"
	},
	fontBold: {
		fontFamily: "fontBold"
	},
	fontLight: {
		fontFamily: "fontLight"
	},
	bold: {
		fontWeight: "bold"
	},
	underline: {
		textDecorationLine: "underline"
	},
	uppercase: {
		textTransform: "uppercase"
	},
	textTransformNone: {
		textTransform: "none"
	},
	fs10: {
		fontSize: 10
	},
	fs12: {
		fontSize: 12
	},
	fs14: {
		fontSize: 14
	},
	fs16: {
		fontSize: 16
	},
	fs18: {
		fontSize: 18
	},
	fs20: {
		fontSize: 20
	},
	fs25: {
		fontSize: 25
	},
	fs40: {
		fontSize: 40
	},
	/* Border */
	rounded30: {
		borderRadius: 30
	},
	rounded22: {
		borderRadius: 22
	},
	roundedTop22: {
		borderTopLeftRadius: 22,
		borderTopRightRadius: 22
	},
	roundedBottom22: {
		borderBottomLeftRadius: 22,
		borderBottomRightRadius: 22
	},
	roundedBottom30: {
		borderBottomLeftRadius: 30,
		borderBottomRightRadius: 30
	},
	rounded10: {
		borderRadius: 10
	},
	rounded5: {
		borderRadius: 5
	},
	borderDarkgrey: {
		borderColor: darkGrayColor,
		borderWidth: 1
	},
	borderBlack: {
		borderColor: "black",
		borderWidth: 1
	},
	borderRed: {
		borderColor: "red",
		borderWidth: 1
	},
	borderBottomBlack: {
		borderBottomColor: "black",
		borderBottomWidth: 1
	},
	borderTopBlack: {
		borderTopColor: "black",
		borderTopWidth: 1
	},
	borderRightBlack: {
		borderRightColor: "black",
		borderRightWidth: 1
	},
	borderLeftBlack: {
		borderLeftColor: "black",
		borderLeftWidth: 1
	},
	borderBottomWhite: {
		borderBottomColor: "white",
		borderBottomWidth: 1
	},
	borderBottomLightblue: {
		borderBottomColor: "#b4d6df",
		borderBottomWidth: 1
	},
	/* Image */
	cover: {
		resizeMode: "cover"
	},
	contain: {
		resizeMode: "contain"
	},
	/* Others */
	textCenter: {
		textAlign: "center"
	},
	h44: {
		// Button/TextInput recommended minimum size
		height: 44
	},
	h40: {
		height: 40
	},
	h100: {
		height: "100%"
	},
	w40: {
		width: 40
	},
	w44: {
		width: 44
	},
	h50: {
		height: 50
	},
	h60: {
		height: 60
	},
	w60: {
		width: 60
	},
	h80: {
		height: 80
	},
	w80: {
		width: 80
	},
	w100: {
		width: "100%"
	},
	w120: {
		width: 120
	},
	w150: {
		width: 150
	},
	w200: {
		width: 200
	},
	none: {
		display: "none"
	},
	opacity07: {
		opacity: 0.7
	},
	opacity05: {
		opacity: 0.5
	},
	opacity1: {
		opacity: 1
	},
	opacity0: {
		opacity: 0
	},
	// Shadow
	shadowGrey: {
		elevation: 5,
		shadowColor: darkGrayColor,
		shadowOpacity: 2,
		shadowRadius: 4,
		shadowOffset: { width: 0, height: 2 }
	},
	// Position
	positionAbsolute: {
		position: "absolute"
	},
	zindex1: {
		zIndex: 1000
	},
	overflowHidden: {
		overflow: "hidden"
	},
	visibilityHidden: {
		visibility: "hidden"
	},
	floatRight: {
		float: "right"
	}
};

export default commonStyles;
