import { createRef, Component } from "react";
import PropTypes from "prop-types";

import ReactTable from "react-table";
import { confirmAlert } from "react-confirm-alert"; // Import

import withStyles from "@material-ui/core/styles/withStyles";
import InputAdornment from "@material-ui/core/InputAdornment";

// @material-ui/core components
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";

// core components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Snackbar from "components/Snackbar/Snackbar";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardIcon from "components/Card/CardIcon";
import CardHeader from "components/Card/CardHeader";
import CardFooter from "components/Card/CardFooter";
import Button from "components/CustomButtons/Button";
import CustomInput from "components/CustomInput/CustomInput";
import CardText from "components/Card/CardText";

// @material-ui/icons
import Close from "@material-ui/icons/Close";
import AddAlert from "@material-ui/icons/AddAlert";
import Delete from "@material-ui/icons/Delete";
import Add from "@material-ui/icons/Add";
import Image from "@material-ui/icons/Image";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import {
	fetchGetCachedImages,
	fetchStoreCachedImage,
	fetchDeleteImage
} from "actions";

import customInputStyle from "assets/jss/material-dashboard-pro-react/components/customInputStyle";
import customSelectStyle from "assets/jss/material-dashboard-pro-react/customSelectStyle";
import customCheckboxRadioSwitch from "assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch";
import extendedTablesStyle from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle";
import commonStyles from "assets/jss/DigiHapi/commonStyles";

import { beigeColor } from "assets/jss/material-dashboard-pro-react";
import Components from "./CachedImagesPage";

import {
	SET_IMAGE_NAME,
	SET_IMAGE_TYPE,
	SET_SUBFOLDER_IMAGES,
	CLEAR_UPLOAD_FILE_VALIDATE,
	VALIDATE_UPLOAD_FILE,
	SET_FILES_IMAGES,
	REMOVE_IMAGE
} from "actions/types.js";
import ImageUpload from "components/CustomUpload/ImageUpload";
import Activity from "components/DigiHapi/Activity";

const style = {
	...customInputStyle,
	...customCheckboxRadioSwitch,
	...extendedTablesStyle,
	...customSelectStyle,
	...commonStyles,
	customUI: {
		backgroundColor: beigeColor[1],
		borderRadius: "10px",
		padding: 10
	},
	inputAdornment: {
		position: "relative"
	},
	img: {
		width: 30,
		height: 30
	}
};

class AddCachedImage extends Component {
	constructor(props) {
		super(props);
		this.child = createRef();
		this.state = {
			br: false
		};
	}

	componentDidUpdate(prevProps, prevState) {
		const { uploadImageResult } = this.props;

		if (uploadImageResult && !prevProps.uploadImageResult) {
			if (this.child.current) {
				//Remove picture
				this.child.current.handleRemove();
			}

			// Display success message
			this.setState({
				br: true
			});
			setTimeout(
				function () {
					this.setState({ br: false });
				}.bind(this),
				6000
			);
		}
	}

	onImageLoaded = (name, imagePreviewUrl) => {
		const { dispatch } = this.props;
		dispatch({
			type: SET_FILES_IMAGES,
			payload: [{ name, base64: imagePreviewUrl }]
		});
	};

	onImageRemoved = () => {
		this.props.dispatch({
			type: REMOVE_IMAGE
		});
	};

	// fetchUploadImages = e => {
	//   e.preventDefault();
	//   const { fetchStoreCachedImage, name, subFolderName, files } = this.props;
	//   fetchStoreCachedImage({ name, subFolderName, files, type });
	// };

	renderMenuItems = () => {
		const { classes, types } = this.props;
		const components = [];

		// eslint-disable-next-line
		for (const item of Object.values(types)) {
			components.push(
				<MenuItem
					key={item.type}
					classes={{
						root: classes.selectMenuItem,
						selected: classes.selectMenuItemSelected
					}}
					value={item}
				>
					{item.label}
				</MenuItem>
			);
		}
		return components;
	};

	render() {
		const {
			classes,
			dispatch,
			name,
			type,
			types,
			subFolderName,
			isFetching,
			onClose,
			onClickAdd
		} = this.props;

		return (
			<GridContainer>
				<GridItem xs={12} sm={12} md={12}>
					<Card>
						<CardHeader color="primary" icon>
							<CardText color="primary">
								<h4 className={classes.cardTitle}>
									{"Ajout d'une image de cache"}
								</h4>
							</CardText>
						</CardHeader>
						<CardBody>
							{isFetching ? (
								<Activity />
							) : (
								<GridContainer justifyContent="center">
									<GridItem xs={12} sm={4} md={4}>
										<legend>Image *</legend>
										<ImageUpload
											ref={this.child}
											onImageLoaded={this.onImageLoaded}
											onImageRemoved={this.onImageRemoved}
											addButtonProps={{
												color: "brown",
												round: true
											}}
											changeButtonProps={{
												color: "brown",
												round: true
											}}
											removeButtonProps={{
												color: "danger",
												round: true
											}}
										/>
									</GridItem>
									<GridItem xs={12} sm={12} md={12}>
										<CustomInput
											labelText="Nom"
											id="name"
											formControlProps={{
												fullWidth: true
											}}
											inputProps={{
												type: "text",
												value: name ? name : undefined,
												autoComplete: "off",
												onChange: e =>
													dispatch({
														type: SET_IMAGE_NAME,
														payload: e.target.value
													})
											}}
										/>
									</GridItem>
									<GridItem xs={12} sm={12} md={12}>
										<FormControl
											fullWidth
											className={classes.selectFormControl}
										>
											<InputLabel
												htmlFor="simple-select"
												className={classes.selectLabel}
											>
												Type *
											</InputLabel>
											<Select
												MenuProps={{
													className: classes.selectMenu
												}}
												classes={{
													select: classes.select
												}}
												value={types[type]}
												onChange={e =>
													dispatch({
														type: SET_IMAGE_TYPE,
														payload: e.target.value
													})
												}
												inputProps={{
													required: true,
													name: "simpleSelect",
													id: "simple-select"
												}}
											>
												{this.renderMenuItems()}
											</Select>
										</FormControl>
									</GridItem>
									<GridItem xs={12} sm={12} md={12}>
										<CustomInput
											labelText="Sous dossier"
											id="subfolder"
											formControlProps={{
												fullWidth: true
											}}
											inputProps={{
												type: "text",
												value: subFolderName ? subFolderName : "",
												autoComplete: "off",
												onChange: e =>
													dispatch({
														type: SET_SUBFOLDER_IMAGES,
														payload: e.target.value
													})
											}}
										/>
									</GridItem>
									<Snackbar
										place="br"
										color="info"
										icon={AddAlert}
										message="Votre image a été correctement téléversée."
										open={this.state.br}
										closeNotification={() => this.setState({ br: false })}
										close
									/>
								</GridContainer>
							)}
						</CardBody>
						<CardFooter product>
							<div className={classes.w100}>
								<Button
									className={classes.floatRight}
									color="primary"
									onClick={onClickAdd}
								>
									<Add />
									{" Ajouter"}
								</Button>
								<Button className={classes.floatRight} onClick={onClose}>
									Annuler
								</Button>
							</div>
						</CardFooter>
					</Card>
				</GridItem>
			</GridContainer>
		);
	}
}

class CachedImagesPage extends Component {
	state = {
		br: false,
		color: "info",
		message: null,
		component: null
	};

	componentDidMount() {
		this.props.fetchGetCachedImages();
	}

	componentDidUpdate(prevProps, prevState) {
		const {
			dispatch,
			fetchGetCachedImages,
			fetchStoreCachedImage,
			files,
			subFolderName,
			name,
			type,
			snackErrorMsg,
			snackInfoMsg,
			clearValidate,
			isValid
		} = this.props;

		if (snackErrorMsg && prevProps.snackErrorMsg !== snackErrorMsg) {
			this.autoCloseMessage();
		}

		if (snackInfoMsg && prevProps.snackInfoMsg !== snackInfoMsg) {
			this.setState({
				component: null
			});
			fetchGetCachedImages();
			this.autoCloseMessage();
		}

		if (clearValidate && !prevProps.clearValidate) {
			dispatch({
				type: VALIDATE_UPLOAD_FILE
			});
		}

		if (isValid && !prevProps.isValid) {
			fetchStoreCachedImage({
				files,
				subFolderName,
				name,
				type
			});
		}
	}

	hideAlert = () => {
		this.setState({
			br: false
		});
	};

	autoCloseMessage = () => {
		const { snackErrorMsg, snackInfoMsg } = this.props;

		this.setState({
			br: true,
			message: snackInfoMsg ? snackInfoMsg : snackErrorMsg,
			color: snackInfoMsg ? "info" : "danger"
		});

		if (snackInfoMsg) {
			setTimeout(this.hideAlert, 5000);
		}
	};

	delete = id => {
		const { classes, fetchDeleteImage } = this.props;
		confirmAlert({
			customUI: ({ onClose }) => {
				return (
					<div className={classes.customUI}>
						<h2>Confirmation</h2>
						<p>{"Êtes-vous sûr de vouloir supprimer l'image ?"}</p>
						<div className={classes.justifyContentSpaceAround}>
							<Button
								onClick={() => {
									onClose();
								}}
							>
								Annuler
							</Button>
							<Button
								color="danger"
								onClick={() => {
									fetchDeleteImage(id);
									onClose();
								}}
							>
								Supprimer
							</Button>
						</div>
					</div>
				);
			}
		});
	};

	onClose = () => {
		this.setState({
			component: null
		});
	};

	clearUploadFileValidate = e => {
		e.preventDefault();
		this.props.dispatch({
			type: CLEAR_UPLOAD_FILE_VALIDATE
		});
	};

	addComponent = () => {
		this.setState({
			component: (
				<Components.AddCachedImage
					onClose={this.onClose}
					onClickAdd={this.clearUploadFileValidate}
				/>
			)
		});
	};

	getRoundButtons = id => {
		const { classes } = this.props;
		return [{ color: "danger", icon: Delete, onClick: this.delete }].map(
			(prop, key) => {
				return (
					<Button
						round
						color={prop.color}
						className={classes.actionButton + " " + classes.actionButtonRound}
						disabled={this.state && this.state.component ? true : false}
						key={key}
						onClick={() => prop.onClick(id)}
					>
						<prop.icon className={classes.icon} />
					</Button>
				);
			}
		);
	};

	getCachedImages = () => {
		const { classes, cachedImages, types } = this.props;

		let cachedImagesObj = [];

		if (cachedImages) {
			cachedImagesObj = cachedImages.map(({ _id, name, type, uri }) => {
				return {
					name,
					type: types[type].label,
					uri: <img className={classes.img} src={uri} />,
					actions: (
						<div className="actions-right">{this.getRoundButtons(_id)}</div>
					)
				};
			});
		}

		return cachedImagesObj;
	};

	render() {
		const { color, message } = this.state;
		const { classes, isFetchingList } = this.props;

		const columns = [
			{
				Header: "Nom",
				accessor: "name",
				sortable: true,
				filterable: false
			},
			{
				Header: "Image",
				accessor: "uri",
				sortable: false,
				filterable: false
			},
			{
				Header: "Type",
				accessor: "type",
				sortable: true,
				filterable: false
			},
			{
				Header: "Actions",
				accessor: "actions",
				sortable: false,
				filterable: false
			}
		];

		return (
			<GridContainer justifyContent="center">
				<GridItem xs={12}>
					<Card>
						<CardHeader icon>
							<CardIcon color="primary">
								<Image />
							</CardIcon>
							<h4 className={classes.cardIconTitle}>{"Images"}</h4>
						</CardHeader>
						<CardBody>
							<ReactTable
								data={this.getCachedImages()}
								filterable
								columns={columns}
								loading={isFetchingList}
								defaultPageSize={5}
								showPaginationTop
								showPaginationBottom={false}
								previousText="Précédent"
								nextText="Suivant"
								rowsText="Lignes"
								noDataText="Aucunes images téléversées"
								ofText="sur"
								loadingText="Chargement..."
								className="-striped -highlight"
							/>
						</CardBody>
						<CardFooter product>
							<div className={classes.w100}>
								<Button
									color={"primary"}
									className={classes.floatRight}
									onClick={this.addComponent}
									disabled={this.state.component}
								>
									<Add />
									{" Ajout d'une image"}
								</Button>
							</div>
						</CardFooter>
					</Card>
				</GridItem>
				{this.state.component}
				<Snackbar
					place="br"
					color={color}
					icon={AddAlert}
					message={message ? message : ""}
					open={this.state.br}
					closeNotification={() => this.setState({ br: false })}
					close
				/>
			</GridContainer>
		);
	}
}

CachedImagesPage.propTypes = {
	classes: PropTypes.object
};

const mapStateToProps = state => {
	const {
		isFetchingList,
		isFetching,
		cachedImages,
		snackInfoMsg,
		snackErrorMsg,
		clearValidate,
		isValid,
		files,
		name,
		type,
		types,
		subFolderName,
		nameErrorMsg,
		imageErrorMsg
	} = state.uploadReducer;

	return {
		isFetchingList,
		isFetching,
		cachedImages,
		snackInfoMsg,
		snackErrorMsg,
		clearValidate,
		isValid,
		files,
		name,
		type,
		types,
		subFolderName,
		nameErrorMsg,
		imageErrorMsg
	};
};

const mapDispatchToProps = dispatch => {
	let actions = bindActionCreators(
		{
			fetchStoreCachedImage,
			fetchGetCachedImages,
			fetchDeleteImage
		},
		dispatch
	);
	return { ...actions, dispatch };
};

export default {
	CachedImagesPage: connect(
		mapStateToProps,
		mapDispatchToProps
	)(withStyles(style)(CachedImagesPage)),
	AddCachedImage: connect(
		mapStateToProps,
		mapDispatchToProps
	)(withStyles(style)(AddCachedImage))
};
