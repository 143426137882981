import { Fragment, useEffect, useState } from "react";

// @material-ui/icons
import AddAlert from "@material-ui/icons/AddAlert";
import CheckCircle from "@material-ui/icons/CheckCircle";

import Snackbar from "components/Snackbar/Snackbar.js";
import { useDispatch, useSelector } from "react-redux";
import { CLEAR_SNACK_MESSAGE } from "actions/types";

export default function SnackBarContainer({
	place = "br",
	snackInfoMsg,
	snackErrorMsg: errorMsg,
	...props
}) {
	const { apiErrorMsg } = useSelector(state => state.errorReducer);
	const dispatch = useDispatch();

	const [open, setOpen] = useState(false);
	const [message, setMessage] = useState(null);
	const [color, setColor] = useState("info");
	const [icon, setIcon] = useState(CheckCircle);

	const snackErrorMsg = errorMsg || apiErrorMsg;

	useEffect(() => {
		if (snackInfoMsg || snackErrorMsg) {
			setOpen(true);
			setMessage(snackInfoMsg ? snackInfoMsg : snackErrorMsg);
			setColor(snackInfoMsg ? "info" : "danger");
			setIcon(snackInfoMsg ? CheckCircle : AddAlert);
			dispatch({
				type: CLEAR_SNACK_MESSAGE
			});
			if (snackInfoMsg) {
				setTimeout(() => setOpen(false), 5000);
			}
		}
	}, [snackInfoMsg, snackErrorMsg]);

	return (
		<Fragment>
			{props.children}
			<Snackbar
				place={place}
				color={color}
				icon={icon}
				message={message ? message : ""}
				open={open}
				closeNotification={() => setOpen(false)}
				close
			/>
		</Fragment>
	);
}
