export const replaceCharForURL = char => {
	// good nothing to change
	if (RegExp("[a-z0-9-]{1}").test(char)) {
		return char;
	}
	// Uppercase to lower case
	if (RegExp("[A-Z]{1}").test(char)) {
		return char.toLowerCase();
	}
	if (RegExp("[àâÀÂ]{1}").test(char)) {
		return "a";
	}
	if (RegExp("[éèêëÉÈÊË]{1}").test(char)) {
		return "e";
	}
	if (RegExp("[îïÎÏ]{1}").test(char)) {
		return "i";
	}
	if (RegExp("[ôöÔÖ]{1}").test(char)) {
		return "o";
	}
	if (RegExp("[ûùüÛÙÜ]{1}").test(char)) {
		return "u";
	}
	if (RegExp("[ç]{1}").test(char)) {
		return "c";
	}
	return "-";
};
