import withStyles from "@material-ui/core/styles/withStyles";
import {
	fetchGetTemplatesCategories,
	fetchReorderTemplatesCategories
} from "actions/template";
import { TEMPLATE_ON_DRAG_END } from "actions/types";
import {
	blueColor,
	infoColor,
	successColor,
	brownColor,
	beigeColor
} from "assets/jss/material-dashboard-pro-react";

import AddAlert from "@material-ui/icons/AddAlert";

import { FaRegNewspaper } from "react-icons/fa";
import { RiHeartsFill } from "react-icons/ri";

import regularFormsStyle from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import commonStyles from "assets/jss/DigiHapi/commonStyles";

import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import CardIcon from "components/Card/CardIcon";
import CardFooter from "components/Card/CardFooter";
import Button from "components/CustomButtons/Button";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Snackbar from "components/Snackbar/Snackbar";

import PropTypes from "prop-types";
import { Component } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Components from "./TemplatesOrderPage";
import { fetchGetPictosUri } from "actions/index";

const templateStyle = {
	...regularFormsStyle,
	templateContainer: {
		display: "flex",
		flexDirection: "column",
		height: 85,
		width: 85,
		borderRadius: 3,
		backgroundColor: "white",
		border: "1px solid #ba986b",
		alignItems: "center",
		// "justify-content": "space-between",
		margin: 5,
		paddingTop: 5
	},
	imageContainer: {
		width: 50,
		height: 50
	},
	image: {
		width: "100%",
		height: "100%",
		"object-fit": "contain"
	},
	text: {
		display: "block",
		width: 85,
		height: 20,
		textAlign: "center",
		fontSize: 11,
		overflow: "hidden",
		// wordWrap: "break-word",
		"text-overflow": "ellipsis"
	},
	bgLigthBlue: {
		backgroundColor: blueColor[0]
		// transition: "backgroud-color 0.2s ease"
	}
};

class Template extends Component {
	render() {
		const { template, classes, index, pictos } = this.props;
		return (
			<Draggable draggableId={template._id} index={index} type="TEMPLATE">
				{(provided, snapshot) => (
					<div
						{...provided.draggableProps}
						{...provided.dragHandleProps}
						ref={provided.innerRef}
						className={
							classes.templateContainer +
							(snapshot.isDragging ? " " + classes.bgLigthBlue : "")
						}
					>
						<div className={classes.imageContainer}>
							{template.photoUri ? (
								<img
									className={classes.image}
									alt={template.name}
									src={template.photoUri}
								/>
							) : pictos && template.picto ? (
								<img
									className={classes.image}
									alt={`${template.picto} made by Freepik from www.flaticon.com`}
									src={pictos[template.picto]}
								/>
							) : null}
						</div>
						<p className={classes.text}>{template.name}</p>
					</div>
				)}
			</Draggable>
		);
	}
}

const templateCategoryStyle = {
	...regularFormsStyle,
	category: {
		color: "#444041"
	},
	categoryContainer: {
		marginTop: "8px",
		border: "1px solid lightgrey",
		borderRadius: "3px",
		padding: "5px",
		backgroundColor: successColor[6]
	},
	templatesContainer: {
		display: "flex",
		// flexDirection: "row",
		marginTop: "8px",
		border: "1px solid lightgrey",
		borderRadius: "3px",
		minHeight: "90px",
		overflow: "auto",
		padding: "5px",
		backgroundColor: infoColor[6]
	},
	bgBeige: {
		backgroundColor: beigeColor[0]
	},
	bgDarkBeige: {
		backgroundColor: beigeColor[2]
	},
	bgDarkInfo: {
		backgroundColor: infoColor[5]
	},
	bgDarkSucces: {
		backgroundColor: successColor[1]
	}
};

class TemplateCategory extends Component {
	onDragEnd = result => {
		this.props.dispatch({
			type: TEMPLATE_ON_DRAG_END,
			result
		});
	};

	render() {
		const { category, classes, index, templates } = this.props;
		return (
			<Draggable draggableId={category._id} index={index} type="CATEGORY">
				{(provided, snapshot) => (
					<div
						className={
							classes.categoryContainer +
							(snapshot.isDragging ? " " + classes.bgDarkSucces : "")
						}
						{...provided.draggableProps}
						ref={provided.innerRef}
					>
						<h4 className={classes.category} {...provided.dragHandleProps}>
							{category.name}
						</h4>
						<Droppable
							droppableId={category._id}
							direction="horizontal"
							type="TEMPLATE"
						>
							{(provided, snapshot) => (
								<div
									className={
										classes.templatesContainer +
										(snapshot.isDraggingOver ? " " + classes.bgDarkInfo : "")
									}
									ref={provided.innerRef}
									{...provided.droppableProps}
								>
									{category.templateIds.map((templateId, index) => (
										<Components.Template
											key={templates[templateId]._id}
											template={templates[templateId]}
											index={index}
										/>
									))}
									{provided.placeholder}
								</div>
							)}
						</Droppable>
					</div>
				)}
			</Draggable>
		);
	}
}

const style = {
	categoriesContainer: {
		maxHeight: "calc(100vh - 400px)",
		overflow: "auto",
		marginTop: "10px"
	},
	categoryContainer: {
		marginTop: "8px",
		border: "1px solid lightgrey",
		borderRadius: "3px",
		padding: "5px",
		backgroundColor: brownColor[3]
	},
	templatesContainer: {
		display: "flex",
		marginTop: "8px",
		border: "1px solid lightgrey",
		borderRadius: "3px",
		minHeight: "90px",
		overflow: "auto",
		padding: "5px",
		backgroundColor: beigeColor[0]
	},
	bgDarkBeige: {
		backgroundColor: beigeColor[2]
	},
	bgDarkInfo: {
		backgroundColor: infoColor[5]
	},
	bgDarkSucces: {
		backgroundColor: successColor[1]
	},
	...commonStyles,
	...regularFormsStyle
};

class TemplatesOrder extends Component {
	constructor(props) {
		super(props);
		this.state = {
			br: false,
			color: "info"
		};
	}

	componentDidMount() {
		const {
			fetchGetPictosUri,
			fetchGetTemplatesCategories,
			type,
			system = false,
			community,
			pictos
		} = this.props;
		if (!pictos) {
			fetchGetPictosUri();
		}
		fetchGetTemplatesCategories(type, system, community._id);
	}

	componentDidUpdate(prevProps, prevState) {
		const {
			fetchGetTemplatesCategories,
			type,
			system = false,
			snackInfoMsg,
			snackErrorMsg,
			community
		} = this.props;

		if (snackErrorMsg && prevProps.snackErrorMsg !== snackErrorMsg) {
			this.autoCloseMessage();
		}

		if (snackInfoMsg && prevProps.snackInfoMsg !== snackInfoMsg) {
			fetchGetTemplatesCategories(type, system, community._id);
			this.autoCloseMessage();
		}
	}

	hideAlert = () => {
		this.setState({
			br: false
		});
	};

	autoCloseMessage = () => {
		const { snackErrorMsg, snackInfoMsg } = this.props;

		this.setState({
			br: true,
			message: snackInfoMsg ? snackInfoMsg : snackErrorMsg,
			color: snackInfoMsg ? "info" : "danger"
		});

		if (snackInfoMsg) {
			setTimeout(this.hideAlert, 5000);
		}
	};

	onClose = () => {
		// TODO
	};

	onDragEnd = result => {
		this.props.dispatch({
			type: TEMPLATE_ON_DRAG_END,
			result
		});
	};

	render() {
		const {
			classes,
			categories,
			fetchReorderTemplatesCategories,
			fetchGetTemplatesCategories,
			areCategoriesReordered,
			type,
			templates,
			system = false,
			community
		} = this.props;
		const { color, message, br } = this.state;

		let category;
		let others;
		if (categories && Object.values(categories).length > 0) {
			[category, ...others] = Object.values(categories);
		}
		return (
			<GridContainer justifyContent="center">
				<GridItem xs={12} sm={12} md={12}>
					<Card noaos>
						<CardHeader color="primary" icon>
							<CardIcon color="primary">
								{type === "Activity" ? <RiHeartsFill /> : <FaRegNewspaper />}
							</CardIcon>
							<h4 className={classes.cardIconTitle}>
								{"Réorganiser "}
								{type === "Activity" ? "les instants partage" : "les modèles"}
							</h4>
						</CardHeader>
						<CardBody>
							{categories && Object.values(categories).length > 0 && (
								<DragDropContext onDragEnd={this.onDragEnd}>
									<div className={classes.categoryContainer}>
										<h4 className={classes.category}>
											{"Piochez ou retirez des éléments ici"}
										</h4>
										<Droppable
											droppableId={category._id}
											direction="horizontal"
											type="TEMPLATE"
										>
											{(provided, snapshot) => (
												<div
													className={
														classes.templatesContainer +
														(snapshot.isDraggingOver
															? " " + classes.bgDarkBeige
															: "")
													}
													ref={provided.innerRef}
													{...provided.droppableProps}
												>
													{category.templateIds.map((templateId, index) => (
														<Components.Template
															key={templates[templateId]._id}
															template={templates[templateId]}
															index={index}
														/>
													))}
													{provided.placeholder}
												</div>
											)}
										</Droppable>
									</div>
									<Droppable droppableId={"categories"} type="CATEGORY">
										{provided => (
											<div
												className={classes.categoriesContainer}
												ref={provided.innerRef}
												{...provided.droppableProps}
											>
												{others.map((category, index) => (
													<Components.TemplateCategory
														key={category._id}
														category={category}
														index={index}
													/>
												))}
												{provided.placeholder}
											</div>
										)}
									</Droppable>
								</DragDropContext>
							)}
							{type !== "Template" && (
								<p>
									Icons made by{" "}
									<a
										href="https://www.freepik.com/"
										target="_blank"
										rel="noopener noreferrer"
									>
										Freepik
									</a>{" "}
									from{" "}
									<a
										href="https://www.flaticon.com/"
										target="_blank"
										rel="noopener noreferrer"
									>
										www.flaticon.com
									</a>
								</p>
							)}
						</CardBody>
						<CardFooter product>
							<div className={classes.w100}>
								<Button
									color="primary"
									disabled={!areCategoriesReordered}
									onClick={() =>
										fetchReorderTemplatesCategories(Object.values(categories))
									}
									className={classes.floatRight}
								>
									{"Réorganiser"}
								</Button>
								<Button
									color="brown"
									disabled={!areCategoriesReordered}
									onClick={() =>
										fetchGetTemplatesCategories(type, system, community._id)
									}
									className={classes.floatRight}
								>
									{"Restaurer"}
								</Button>
							</div>
						</CardFooter>
					</Card>
				</GridItem>
				<Snackbar
					place="br"
					color={color}
					icon={AddAlert}
					message={message ? message : ""}
					open={br}
					closeNotification={() => this.setState({ br: false })}
					close
				/>
			</GridContainer>
		);
	}
}

class TemplatesOrderPage extends TemplatesOrder {
	static defaultProps = {
		type: "Template"
	};
}

class ActivitiesOrderPage extends TemplatesOrder {
	static defaultProps = {
		type: "Activity"
	};
}

class ProposalsOrderPage extends TemplatesOrder {
	static defaultProps = {
		type: "Proposal"
	};
}

class SystemTemplatesOrderPage extends TemplatesOrder {
	static defaultProps = {
		type: "Template",
		system: true
	};
}

class SystemActivitiesOrderPage extends TemplatesOrder {
	static defaultProps = {
		type: "Activity",
		system: true
	};
}

class SystemProposalsOrderPage extends TemplatesOrder {
	static defaultProps = {
		type: "Proposal",
		system: true
	};
}

TemplatesOrderPage.propTypes = {
	classes: PropTypes.object
};

const mapStateToProps = state => {
	const {
		isFetching,
		categories,
		areCategoriesReordered,
		snackInfoMsg,
		snackErrorMsg
	} = state.templateCategoryReducer;
	const { templates, community } = state.entitiesReducer;
	const { pictos } = state.uploadReducer;

	return {
		isFetching,
		categories,
		templates,
		areCategoriesReordered,
		snackInfoMsg,
		snackErrorMsg,
		community,
		pictos
	};
};

const mapDispatchToProps = dispatch => {
	let actions = bindActionCreators(
		{
			fetchGetPictosUri,
			fetchGetTemplatesCategories,
			fetchReorderTemplatesCategories
		},
		dispatch
	);
	return { ...actions, dispatch };
};

export default {
	TemplatesOrderPage: connect(
		mapStateToProps,
		mapDispatchToProps
	)(withStyles(style)(TemplatesOrderPage)),
	ActivitiesOrderPage: connect(
		mapStateToProps,
		mapDispatchToProps
	)(withStyles(style)(ActivitiesOrderPage)),
	ProposalsOrderPage: connect(
		mapStateToProps,
		mapDispatchToProps
	)(withStyles(style)(ProposalsOrderPage)),
	SystemTemplatesOrderPage: connect(
		mapStateToProps,
		mapDispatchToProps
	)(withStyles(style)(SystemTemplatesOrderPage)),
	SystemActivitiesOrderPage: connect(
		mapStateToProps,
		mapDispatchToProps
	)(withStyles(style)(SystemActivitiesOrderPage)),
	SystemProposalsOrderPage: connect(
		mapStateToProps,
		mapDispatchToProps
	)(withStyles(style)(SystemProposalsOrderPage)),
	TemplateCategory: connect(mapStateToProps)(
		withStyles(templateCategoryStyle)(TemplateCategory)
	),
	Template: connect(mapStateToProps)(withStyles(templateStyle)(Template))
};
