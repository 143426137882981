import {
	container,
	cardTitle,
	whiteColor,
	grayColor,
	darkGrayColor
} from "assets/jss/material-dashboard-pro-react.js";
import commonStyles from "assets/jss/DigiHapi/commonStyles.js";

const adminLoginPageStyle = theme => ({
	...commonStyles,
	container: {
		...container,
		zIndex: "4",
		[theme.breakpoints.down("sm")]: {
			paddingBottom: "100px"
		}
	},
	cardTitle: {
		...cardTitle,
		color: darkGrayColor,
		fontSize: 30
	},
	textCenter: {
		textAlign: "center"
	},
	justifyContentCenter: {
		justifyContent: "center !important"
	},
	customButtonClass: {
		"&,&:focus,&:hover": {
			color: whiteColor
		},
		marginLeft: "5px",
		marginRight: "5px"
	},
	inputAdornment: {
		marginRight: "18px"
	},
	inputAdornmentIcon: {
		color: grayColor[6]
	},
	cardHidden: {
		opacity: "0",
		transform: "translate3d(0, -60px, 0)"
	},
	cardHeader: {
		marginBottom: "20px"
	},
	socialLine: {
		padding: "0.9375rem 0",
		width: "100%"
	},
	fontWeight900: {
		fontWeight: 900,
		fontSize: "40px !important"
	},
	hoverRed: {
		"&:hover": {
			color: "red"
		}
	},
	mb80: {
		marginBottom: 80
	},
	bgWhite07: {
		backgroundColor: "rgba(255, 255, 255, 0.7)"
	}
});

export default adminLoginPageStyle;
