// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import CustomInput from "components/CustomInput/CustomInput";

import {
	SET_TEMPLATE_NAME_EN,
	SET_TEMPLATE_DESCRIPTION_EN,
	SET_TEMPLATE_POST_DESCRIPTION_EN
} from "actions/types";
import customInputStyle from "assets/jss/material-dashboard-pro-react/components/customInputStyle";
// core components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import PropTypes from "prop-types";
import { Component } from "react";
import { connect } from "react-redux";

const style = {
	infoText: {
		fontWeight: "300",
		margin: "10px 0 30px",
		textAlign: "center"
	},
	inputAdornmentIcon: {
		color: "#555"
	},
	inputAdornment: {
		position: "relative"
	},
	...customInputStyle
};

class Step3 extends Component {
	sendState() {
		return this.state;
	}

	isValidated() {
		return true;
	}

	render() {
		const { dispatch, lang } = this.props;
		return (
			<GridContainer justifyContent="center">
				<GridItem xs={12} sm={12} md={12}>
					<CustomInput
						labelText="Nom"
						id="name-en"
						formControlProps={{
							fullWidth: true
						}}
						inputProps={{
							value: lang && lang.en ? lang.en.name : "",
							onChange: e =>
								dispatch({
									type: SET_TEMPLATE_NAME_EN,
									payload: e.target.value
								})
						}}
					/>
				</GridItem>
				<GridItem xs={12} sm={12} md={12}>
					<CustomInput
						labelText="Description"
						id="descrption-en"
						formControlProps={{
							fullWidth: true
						}}
						inputProps={{
							value: lang && lang.en ? lang.en.description : "",
							onChange: e =>
								dispatch({
									type: SET_TEMPLATE_DESCRIPTION_EN,
									payload: e.target.value
								}),
							multiline: true,
							rows: 5
						}}
					/>
				</GridItem>
				<GridItem xs={12} sm={12} md={12}>
					<CustomInput
						labelText="Post photo description"
						id="post-descrption-en"
						formControlProps={{
							fullWidth: true
						}}
						inputProps={{
							value:
								lang && lang.en && lang.en.postDescription
									? lang.en.postDescription
									: "",
							onChange: e =>
								dispatch({
									type: SET_TEMPLATE_POST_DESCRIPTION_EN,
									payload: e.target.value
								}),
							multiline: true,
							rows: 3
						}}
					/>
				</GridItem>
			</GridContainer>
		);
	}
}

Step3.propTypes = {
	classes: PropTypes.object
};

const mapStateToProps = state => {
	const { lang } = state.templateReducer;

	return {
		lang
	};
};

export default connect(mapStateToProps)(withStyles(style)(Step3));
