import {
	FETCH_GET_LOCATIONS,
	SET_LOCATIONS_RESULT,
	SET_LOCATIONS_FAILURE,
	FETCH_ADD_LOCATION,
	SET_ADD_LOCATION_RESULT,
	SET_ADD_LOCATION_FAILURE,
	FETCH_UPDATE_LOCATION,
	SET_UPDATE_LOCATION_RESULT,
	SET_UPDATE_LOCATION_FAILURE,
	FETCH_DELETE_LOCATION,
	SET_DELETE_LOCATION_RESULT,
	SET_DELETE_LOCATION_FAILURE,
	FETCH_REORDER_LOCATIONS,
	SET_REORDER_LOCATIONS_RESULT,
	SET_REORDER_LOCATIONS_FAILURE
} from "./types";
import { apiAction } from "./api";
import { schema } from "normalizr";

export const location = new schema.Entity(
	"locations",
	{},
	{
		idAttribute: "_id"
	}
);

export function fetchReorderLocations(data) {
	return apiAction({
		url: "/locations/order",
		method: "POST",
		data,
		onSuccess: setReorderLocationsResult,
		onFailure: setReorderLocationsFailure,
		label: FETCH_REORDER_LOCATIONS
	});
}

function setReorderLocationsResult(data) {
	return {
		type: SET_REORDER_LOCATIONS_RESULT,
		payload: data
	};
}

function setReorderLocationsFailure(error) {
	return {
		type: SET_REORDER_LOCATIONS_FAILURE,
		error
	};
}

export function fetchGetLocations(communityId) {
	return apiAction({
		url: `/locations${communityId ? "?communityId=" + communityId : ""}`,
		method: "GET",
		schema: [location],
		onSuccess: setLocationsResult,
		onFailure: setLocationsFailure,
		label: FETCH_GET_LOCATIONS
	});
}

function setLocationsResult(normalized) {
	return {
		type: SET_LOCATIONS_RESULT,
		entities: normalized.entities
	};
}

function setLocationsFailure(error) {
	return {
		type: SET_LOCATIONS_FAILURE,
		error
	};
}

export function fetchAddLocation(location) {
	return apiAction({
		url: "/locations",
		method: "POST",
		data: location,
		onSuccess: setAddLocationsResult,
		onFailure: setAddLocationsFailure,
		label: FETCH_ADD_LOCATION
	});
}

function setAddLocationsResult(data) {
	return {
		type: SET_ADD_LOCATION_RESULT,
		payload: data
	};
}

function setAddLocationsFailure(error) {
	return {
		type: SET_ADD_LOCATION_FAILURE,
		error
	};
}

export function fetchUpdateLocation(location) {
	return apiAction({
		url: "/locations/" + location._id,
		method: "PUT",
		data: location,
		onSuccess: setUpdateLocationsResult,
		onFailure: setUpdateLocationsFailure,
		label: FETCH_UPDATE_LOCATION
	});
}

function setUpdateLocationsResult(data) {
	return {
		type: SET_UPDATE_LOCATION_RESULT,
		payload: data
	};
}

function setUpdateLocationsFailure(error) {
	return {
		type: SET_UPDATE_LOCATION_FAILURE,
		error
	};
}

export function fetchDeleteLocation(locationId) {
	return apiAction({
		url: "/locations/" + locationId,
		method: "DELETE",
		onSuccess: setDeleteLocationsResult,
		onFailure: setDeleteLocationsFailure,
		label: FETCH_DELETE_LOCATION
	});

	function setDeleteLocationsResult(data) {
		return {
			type: SET_DELETE_LOCATION_RESULT,
			payload: data
		};
	}

	function setDeleteLocationsFailure(error) {
		return {
			type: SET_DELETE_LOCATION_FAILURE,
			error
		};
	}
}
