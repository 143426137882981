export const Images = {
	home: require("./img/bg-home.jpg"),

	maintenance: require("./img/cookie-the-pom-gySMaocSdqs-unsplash.jpg"),

	ocamping: require("./img/bg-tent.jpg"),
	hapicolibri: require("./img/hapicolibri.jpg"),

	logo: require("./img/logo.png"),
	logodigihapi: require("./img/logoDigihapi.png"),
	logorounded: require("./img/logo-rounded.png"),
	logobicolor: require("./img/logo-bicolor.png"),
	icon: require("./img/icon.png")
};

export const getImage = name => {
	if (!name) {
		return name;
	}
	return Images[name].default;
};

export const getImageUri = name => {
	return { uri: Images[name] };
};
