// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";

import {
	SET_TEMPLATE_NAME_EN,
	SET_ACTIVITY_POST_DESCRIPTION_EN,
	SET_ACTIVITY_DESCRIPTION_EN,
	SELECT_ACTIVITY_CHOICE_EN,
	SET_ACTIVITY_NAME_EN,
	SET_ACTIVITY_GOODDEAL_DESCRIPTION_EN,
	SET_ACTIVITY_GOODDEAL_POST_DESCRIPTION_EN
} from "actions/types";
import customSelectStyle from "assets/jss/material-dashboard-pro-react/customSelectStyle";
import customInputStyle from "assets/jss/material-dashboard-pro-react/components/customInputStyle";
import regularFormsStyle from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";

// core components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import CustomInput from "components/CustomInput/CustomInput";

import PropTypes from "prop-types";
import { Component } from "react";
import { connect } from "react-redux";

const style = {
	infoText: {
		fontWeight: "300",
		margin: "10px 0 30px",
		textAlign: "center"
	},
	inputAdornmentIcon: {
		color: "#555"
	},
	inputAdornment: {
		position: "relative"
	},
	...customInputStyle,
	...customSelectStyle,
	...regularFormsStyle
};

class Step3_Activities extends Component {
	sendState() {
		return this.state;
	}

	isValidated() {
		return true;
	}

	renderMenuItems = choices => {
		const { classes } = this.props;
		const components = [];

		// eslint-disable-next-line
		for (const obj of Object.values(choices)) {
			components.push(
				<MenuItem
					key={obj.name}
					classes={{
						root: classes.selectMenuItem,
						selected: classes.selectMenuItemSelected
					}}
					value={obj.name}
				>
					{obj.name}
				</MenuItem>
			);
		}
		return components;
	};

	render() {
		const {
			classes,
			dispatch,
			lang,
			choices,
			choiceId,
			descriptionEn,
			postDescriptionEn,
			nameEn,
			gooddealDescriptionEn,
			gooddealPostDescriptionEn
		} = this.props;
		return (
			<GridContainer justifyContent="center">
				{!choices && (
					<GridItem xs={12} sm={12} md={12}>
						<CustomInput
							labelText="Nom"
							id="name-en"
							formControlProps={{
								fullWidth: true
							}}
							inputProps={{
								required: true,
								value: lang && lang.en ? lang.en.name : "",
								onChange: e =>
									dispatch({
										type: SET_TEMPLATE_NAME_EN,
										payload: e.target.value
									})
							}}
						/>
					</GridItem>
				)}
				{choices && (
					<GridItem xs={12} sm={12} md={12}>
						<FormControl fullWidth className={classes.selectFormControl}>
							<InputLabel
								htmlFor="simple-select"
								className={classes.selectLabel}
							>
								Sélectionner le choix à mettre à jour
							</InputLabel>
							<Select
								MenuProps={{
									className: classes.selectMenu
								}}
								classes={{
									select: classes.select
								}}
								value={choiceId ? choiceId : ""}
								onChange={e =>
									dispatch({
										type: SELECT_ACTIVITY_CHOICE_EN,
										payload: e.target.value
									})
								}
								inputProps={{
									required: true,
									name: "simpleSelect",
									id: "simple-select"
								}}
							>
								<MenuItem
									disabled
									classes={{
										root: classes.selectMenuItem
									}}
								>
									Choix
								</MenuItem>
								{this.renderMenuItems(choices)}
							</Select>
						</FormControl>
					</GridItem>
				)}
				{choices && (
					<GridItem xs={12} sm={12} md={12}>
						<CustomInput
							labelText="Nom"
							id="set-name-en"
							formControlProps={{
								fullWidth: true
							}}
							inputProps={{
								required: true,
								value: nameEn ? nameEn : "",
								onChange: e =>
									dispatch({
										type: SET_ACTIVITY_NAME_EN,
										payload: e.target.value
									})
							}}
						/>
					</GridItem>
				)}
				<GridItem xs={12} sm={12} md={12}>
					<CustomInput
						labelText="Description"
						id="description-en"
						formControlProps={{
							fullWidth: true
						}}
						inputProps={{
							value: choices
								? descriptionEn
									? descriptionEn
									: ""
								: lang && lang.en
								? lang.en.description
								: "",
							required: true,
							onChange: e =>
								dispatch({
									type: SET_ACTIVITY_DESCRIPTION_EN,
									payload: e.target.value
								}),
							multiline: true,
							rows: 5
						}}
					/>
					<CustomInput
						labelText="Description après photo"
						id="post-description-en"
						formControlProps={{
							fullWidth: true
						}}
						inputProps={{
							value: choices
								? postDescriptionEn
									? postDescriptionEn
									: ""
								: lang && lang.en
								? lang.en.postDescription
								: "",
							onChange: e =>
								dispatch({
									type: SET_ACTIVITY_POST_DESCRIPTION_EN,
									payload: e.target.value
								}),
							multiline: true,
							rows: 3
						}}
					/>
				</GridItem>
				<GridItem xs={12} sm={12} md={12}>
					<CustomInput
						labelText="Description pour les annonces"
						id="gooddeal-description-en"
						formControlProps={{
							fullWidth: true
						}}
						inputProps={{
							value: gooddealDescriptionEn ? gooddealDescriptionEn : "",
							required: true,
							onChange: e =>
								dispatch({
									type: SET_ACTIVITY_GOODDEAL_DESCRIPTION_EN,
									payload: e.target.value
								}),
							multiline: true,
							rows: 5
						}}
					/>
					<CustomInput
						labelText="Description après photo pour les annonces"
						id="gooddeal-post-description-en"
						formControlProps={{
							fullWidth: true
						}}
						inputProps={{
							value: gooddealPostDescriptionEn ? gooddealPostDescriptionEn : "",
							onChange: e =>
								dispatch({
									type: SET_ACTIVITY_GOODDEAL_POST_DESCRIPTION_EN,
									payload: e.target.value
								}),
							multiline: true,
							rows: 3
						}}
					/>
				</GridItem>
			</GridContainer>
		);
	}
}

Step3_Activities.propTypes = {
	classes: PropTypes.object
};

const mapStateToProps = state => {
	const {
		name,
		choices,
		choiceId,
		descriptionEn,
		postDescriptionEn,
		lang,
		nameEn,
		gooddealDescriptionEn,
		gooddealPostDescriptionEn
	} = state.templateReducer;

	return {
		name,
		choices,
		choiceId,
		descriptionEn,
		postDescriptionEn,
		lang,
		nameEn,
		gooddealDescriptionEn,
		gooddealPostDescriptionEn
	};
};

export default connect(mapStateToProps)(withStyles(style)(Step3_Activities));
