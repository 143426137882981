import { CLEAR_SNACK_MESSAGE, FETCH_FAILURE } from "../actions/types";

const initialState = {
	apiErrorMsg: null
};

export default function errorReducer(state = initialState, action = {}) {
	switch (action.type) {
		case FETCH_FAILURE:
			return {
				...state,
				apiErrorMsg: action.payload
			};

		case CLEAR_SNACK_MESSAGE:
			return {
				...state,
				apiErrorMsg: null
			};

		default:
			return state;
	}
}
