import { createRef, Fragment, useState, useEffect } from "react";
import cx from "classnames";
import { Switch, Route, Redirect } from "react-router-dom";
// creates a beautiful scrollbar
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import AdminNavbar from "components/Navbars/AdminNavbar.js";
import Footer from "components/Footer/Footer.js";
import Sidebar from "components/Sidebar/Sidebar.js";
// import FixedPlugin from "components/FixedPlugin/FixedPlugin.js";

import routesWebmaster from "routesWebmaster";
import routesAdministrator from "routesAdmin";
import routesEmployee from "routesEmployee";

import styles from "assets/jss/material-dashboard-pro-react/layouts/adminStyle.js";

import { fetchGetUser } from "actions/profile";
import { fetchLogout } from "actions/login";

// Redux
import { useDispatch, useSelector } from "react-redux";
import {
	ROLE_WEBMASTER,
	ROLE_ADMINISTRATOR,
	ROLE_EMPLOYEE
} from "actions/types";
import { getImage } from "assets/Images";

var ps;

const useStyles = makeStyles(styles);

export default function Admin(props) {
	const { history, ...rest } = props;
	const pathname = history?.location?.pathname;

	// states and functions
	const [mobileOpen, setMobileOpen] = useState(false);
	const [miniActive, setMiniActive] = useState(false);
	// const [image, setImage] = useState(require("assets/img/sidebar-2.jpg"));
	// const [color, setColor] = useState("blue");
	// const [bgColor, setBgColor] = useState("black");
	// const [hasImage, setHasImage] = useState(true);
	// const [fixedClasses, setFixedClasses] = useState("dropdown");
	// const [logo, setLogo] = useState(getImage("logorounded"));
	// styles
	const classes = useStyles();
	const dispatch = useDispatch();

	const { user } = useSelector(state => state.profileReducer);
	const { logoutResult } = useSelector(state => state.loginReducer);

	const mainPanelClasses =
		classes.mainPanel +
		" " +
		cx({
			[classes.mainPanelSidebarMini]: miniActive,
			[classes.mainPanelWithPerfectScrollbar]:
				navigator.platform.indexOf("Win") > -1
		});
	// ref for main panel div
	const mainPanel = createRef();

	// effect instead of componentDidMount, componentDidUpdate and componentWillUnmount
	useEffect(() => {
		if (!user) {
			dispatch(fetchGetUser());
		}

		if (navigator.platform.indexOf("Win") > -1) {
			ps = new PerfectScrollbar(mainPanel.current, {
				suppressScrollX: true,
				suppressScrollY: false
			});
			document.body.style.overflow = "hidden";
		}
		window.addEventListener("resize", resizeFunction);

		// Specify how to clean up after this effect:
		return function cleanup() {
			if (navigator.platform.indexOf("Win") > -1) {
				ps.destroy();
			}
			window.removeEventListener("resize", resizeFunction);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (logoutResult) {
			history.push("/");
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [logoutResult]);

	useEffect(() => {
		if (pathname) {
			mainPanel.current.scrollTop = 0;
			setMobileOpen(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [pathname]);

	// functions for changeing the states from components
	// const handleImageClick = image => {
	//   setImage(image);
	// };

	// const handleColorClick = color => {
	//   setColor(color);
	// };

	// const handleBgColorClick = bgColor => {
	//   switch (bgColor) {
	//     case "white":
	//       setLogo(require("assets/img/logo.svg"));
	//       break;
	//     default:
	//       setLogo(require("assets/img/logo-white.svg"));
	//       break;
	//   }
	//   setBgColor(bgColor);
	// };

	// const handleFixedClick = () => {
	//   if (fixedClasses === "dropdown") {
	//     setFixedClasses("dropdown show");
	//   } else {
	//     setFixedClasses("dropdown");
	//   }
	// };

	const handleDrawerToggle = () => {
		setMobileOpen(!mobileOpen);
	};

	const getRoute = () => {
		return window.location.pathname !== "/admin/full-screen-maps";
	};

	const getActiveRoute = routes => {
		let activeRoute = "Default Brand Text";
		for (let i = 0; i < routes.length; i++) {
			if (routes[i].collapse) {
				let collapseActiveRoute = getActiveRoute(routes[i].views);
				if (collapseActiveRoute !== activeRoute) {
					return collapseActiveRoute;
				}
			} else {
				if (
					window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
				) {
					return routes[i].name;
				}
			}
		}
		return activeRoute;
	};

	const getRoutes = routes => {
		return routes.map((prop, key) => {
			if (prop.collapse) {
				return getRoutes(prop.views);
			}
			if (user) {
				if (
					(prop.layout === "/team" && user.role === ROLE_EMPLOYEE) ||
					((prop.layout === "/team" || prop.layout === "/admin") &&
						user.role === ROLE_ADMINISTRATOR) ||
					((prop.layout === "/team" ||
						prop.layout === "/admin" ||
						prop.layout === "/webmaster") &&
						user.role === ROLE_WEBMASTER)
				) {
					return (
						<Route
							path={prop.layout + prop.path}
							component={prop.component}
							key={key}
						/>
					);
				} else {
					dispatch(fetchLogout());
					return null;
				}
			} else {
				return null;
			}
		});
	};

	const sidebarMinimize = () => {
		setMiniActive(!miniActive);
	};

	const resizeFunction = () => {
		if (window.innerWidth >= 960) {
			setMobileOpen(false);
		}
	};

	let myRoutes = routesEmployee;

	if (user) {
		switch (user.role) {
			case ROLE_WEBMASTER:
				myRoutes = routesWebmaster;
				break;
			case ROLE_ADMINISTRATOR:
				myRoutes = routesAdministrator;
				break;
			default:
				// Employee
				break;
		}
	}

	return (
		<div className={classes.wrapper}>
			<Sidebar
				routes={myRoutes}
				logoText={"Administration"}
				logo={getImage("logorounded")}
				image={require("assets/img/sidebar-2.jpg").default}
				handleDrawerToggle={handleDrawerToggle}
				open={mobileOpen}
				color="primary"
				bgColor="black"
				miniActive={miniActive}
				{...rest}
			/>
			<div className={mainPanelClasses} ref={mainPanel}>
				<AdminNavbar
					sidebarMinimize={sidebarMinimize.bind(this)}
					miniActive={miniActive}
					brandText={getActiveRoute(myRoutes)}
					handleDrawerToggle={handleDrawerToggle}
					{...rest}
				/>
				{/* On the /maps/full-screen-maps route we want the map to be on full screen - this is not possible if the content and conatiner classes are present because they have some paddings which would make the map smaller */}
				{getRoute() ? (
					<div className={classes.content}>
						<div className={classes.container}>
							<Switch>
								{getRoutes(myRoutes)}
								{user &&
									user.role === ROLE_EMPLOYEE &&
									getActiveRoute(myRoutes) === "Default Brand Text" && (
										<Redirect from="/team" to="/team/community" />
									)}
								{user &&
									user.role === ROLE_ADMINISTRATOR &&
									getActiveRoute(myRoutes) === "Default Brand Text" && (
										<Fragment>
											<Redirect from="/team" to="/team/community" />
											<Redirect from="/admin" to="/team/community" />
										</Fragment>
									)}
								{user &&
									user.role === ROLE_WEBMASTER &&
									getActiveRoute(myRoutes) === "Default Brand Text" && (
										<Fragment>
											<Redirect from="/team" to="/team/community" />
											<Redirect from="/admin" to="/team/community" />
											<Redirect from="/webmaster" to="/team/community" />
										</Fragment>
									)}
								)}
							</Switch>
						</div>
					</div>
				) : (
					<div className={classes.map}>
						<Switch>
							{getRoutes(myRoutes)}
							<Redirect from="/team" to="/team/community" />
							<Redirect from="/admin" to="/team/community" />
							<Redirect from="/webmaster" to="/team/community" />
						</Switch>
					</div>
				)}
				{getRoute() ? <Footer fluid /> : null}
				{/* <FixedPlugin
          handleImageClick={handleImageClick}
          handleColorClick={handleColorClick}
          handleBgColorClick={handleBgColorClick}
          color={color}
          bgColor={bgColor}
          bgImage={image}
          handleFixedClick={handleFixedClick}
          fixedClasses={fixedClasses}
          sidebarMinimize={sidebarMinimize.bind(this)}
          miniActive={miniActive}
        /> */}
			</div>
		</div>
	);
}
