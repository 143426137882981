/*eslint-disable*/
import { makeStyles } from "@material-ui/core/styles";

import styles from "assets/jss/DigiHapi/components/fullPageImageStyle.js";

// nodejs library to set properties for components
import PropTypes from "prop-types";

import { getImage } from "assets/Images";

const useStyles = makeStyles(styles);

export default function FullPageImage(props) {
	const classes = useStyles();
	const { bgImage, child } = props;
	return (
		<div
			className={classes.fullPage}
			style={{
				backgroundImage: "url(" + getImage(bgImage) + ")"
			}}
		>
			{child}
		</div>
	);
}

FullPageImage.propTypes = {
	bgImage: PropTypes.string.isRequired
};
