import {
	API_START,
	LOGIN_FAILURE,
	API_END,
	FETCH_LOGIN,
	SIGNUP_RESULT,
	LOGOUT_RESULT,
	FETCH_SIGNUP,
	SET_EMAIL_OR_PSEUDO,
	SET_PASSWORD,
	VALIDATE_INPUTS,
	CLEAR_VALIDATE,
	SET_SELECTED_CATEGORY,
	SET_PASSWORD_CAMP,
	FETCH_FORGET_PASSWORD,
	FORGET_PASSWORD_FAILURE,
	FORGET_PASSWORD_RESULT,
	DELETE_USER_RESULT,
	SET_SID,
	SET_CAMPSITE_ID,
	// SET_CAMPSITES,
	SET_CAMPSITE,
	SET_LOGIN_WITH_FB_RESULT,
	VALIDATE_FB_INPUTS,
	CLEAR_VALIDATE_FB,
	FETCH_SIGNUP_WITH_FB,
	SET_PROFILE_DETAILS,
	FETCH_LOGIN_WITH_FB,
	FETCH_CLEAR_PASSWORD,
	SET_CLEAR_PASSWORD_RESULT,
	SET_CLEAR_PASSWORD_FAILURE,
	SET_LOGIN_WITH_FB_ERROR,
	API_ERROR,
	SET_USER_NEW_PASSWORD,
	SET_USER_NEW_PASSWORD_CONFIRM,
	CLEAR_PASSWORD_VALIDATE,
	CLEAR_FORGET_PWD_VALIDATE,
	VALIDATE_FORGET_PWD_INPUT,
	VALIDATE_PASSWORD_INPUTS,
	FETCH_CHANGE_PASSWORD,
	SET_CHANGE_PASSWORD_RESULT,
	SET_CHANGE_PASSWORD_FAILURE,
	RESET_DATA,
	FETCH_ACTIVATE_ACCOUNT,
	SET_ACTIVATE_ACCOUNT_RESULT,
	SET_ACTIVATE_ACCOUNT_FAILURE,
	SET_REGISTER_PROFESSIONAL_FAILURE,
	SET_LAST_NAME,
	SET_FIRST_NAME,
	SET_GENDER,
	SET_REGISTER_PROFESSIONAL_RESULT,
	FETCH_LOGIN_USING_TOKEN,
	SET_STRATEGY
} from "../actions/types";
import { Record } from "immutable";
import { t } from "../services/i18n";

function validateEmail(email, mandatory = true) {
	let emailErrorMsg = null;
	const re =
		/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	if (mandatory && !email) {
		emailErrorMsg = t("login:missingemail");
	} else if (!re.test(email)) {
		emailErrorMsg = t("login:invalidemail");
	}

	return emailErrorMsg;
}

function validatePassword(password, passwordConfirm) {
	let passwordErrorMsg = null;
	if (!password) {
		passwordErrorMsg = t("profile:passwordmandatory");
	} else if (password.length < 8) {
		passwordErrorMsg = t("profile:invalidpassword");
	} else if (password !== passwordConfirm) {
		passwordErrorMsg = t("profile:passwordnotequals");
	}
	return passwordErrorMsg;
}

const InitialState = new Record({
	emailOrPseudo: null,
	communityId: null,
	campsiteErrorMsg: null,
	emailOrPseudoErrorMsg: null,
	password: null,
	newpassword: null,
	newpasswordConfirm: null,
	passwordErrorMsg: null,
	newpasswordErrorMsg: null,
	logoutResult: null,
	deleteUserResult: null,
	isFetching: false,
	clearValidate: false,
	valid: false,
	selectedCategory: 0,
	sid: null,
	isFBauthentication: false,
	validFB: false,
	expires: null,
	permissions: null,
	declinedPermissions: null,
	profileData: null,
	snackInfoMsg: null,
	snackErrorMsg: null,
	isClearPasswordValidated: false,
	isPwdValid: false,
	gender: "M",
	firstname: null,
	lastname: null,
	firstnameErrorMsg: null,
	lastnameErrorMsg: null,
	strategy: null,
	validForgetPwd: false,
	clearForgetPwdValidate: false
});

const initialState = new InitialState();
export default function loginReducer(state = initialState, action = {}) {
	switch (action.type) {
		case API_START: {
			switch (action.payload) {
				case FETCH_LOGIN:
				case FETCH_SIGNUP:
				case FETCH_LOGIN_WITH_FB:
				case FETCH_SIGNUP_WITH_FB:
				case FETCH_FORGET_PASSWORD:
				case FETCH_CLEAR_PASSWORD:
				case FETCH_ACTIVATE_ACCOUNT:
				case FETCH_CHANGE_PASSWORD:
				case FETCH_LOGIN_USING_TOKEN:
					return state
						.set("isFetching", true)
						.set("snackInfoMsg", null)
						.set("snackErrorMsg", null)
						.set("emailOrPseudoErrorMsg", null)
						.set("passwordErrorMsg", null)
						.set("logoutResult", null);
				default:
					break;
			}
			break;
		}

		case CLEAR_FORGET_PWD_VALIDATE:
			return state
				.set("validForgetPwd", false)
				.set("clearForgetPwdValidate", true)
				.set("emailOrPseudoErrorMsg", null)
				.set("passwordErrorMsg", null)
				.set("tokenErrorMsg", null)
				.set("registerkeyErrorMsg", null);

		case VALIDATE_FORGET_PWD_INPUT: {
			const emailOrPseudoErrorMsg = validateEmail(state.emailOrPseudo);
			return state
				.set("validForgetPwd", emailOrPseudoErrorMsg === null)
				.set("clearForgetPwdValidate", false)
				.set("emailOrPseudoErrorMsg", emailOrPseudoErrorMsg);
		}

		case FORGET_PASSWORD_RESULT:
			return state.set("snackInfoMsg", t("login:popup3"));

		case SET_STRATEGY:
			return state.set("strategy", action.payload);

		case RESET_DATA:
			return initialState;

		case SET_CLEAR_PASSWORD_RESULT:
			return state.set("snackInfoMsg", action.payload.message);

		case SET_CLEAR_PASSWORD_FAILURE:
			return state.set("snackErrorMsg", action.payload.response.data);

		case SET_ACTIVATE_ACCOUNT_RESULT:
			return state.set("snackInfoMsg", action.payload.message);

		case SET_ACTIVATE_ACCOUNT_FAILURE:
			return state.set("snackErrorMsg", action.payload.response.data);

		case SET_SID:
			return state.set("sid", action.payload);

		case FORGET_PASSWORD_FAILURE:
			if (("" + action.payload).endsWith("404")) {
				return state.set("snackInfoMsg", t("login:popup3"));
			}
			return state.set("snackErrorMsg", t("login:unexpectederror"));

		case SET_EMAIL_OR_PSEUDO:
			return state
				.set("emailOrPseudo", action.value)
				.set("emailOrPseudoErrorMsg", null)
				.set("valid", false)
				.set("validForgetPwd", false)
				.set("passwordErrorMsg", null);

		case SET_PASSWORD:
			return state
				.set("password", action.value)
				.set("valid", false)
				.set("emailOrPseudoErrorMsg", null)
				.set("passwordErrorMsg", null);

		case SET_USER_NEW_PASSWORD: {
			const newpasswordErrorMsg = validatePassword(
				action.value,
				state.newpasswordConfirm
			);
			return state
				.set("newpassword", action.value)
				.set("newpasswordErrorMsg", newpasswordErrorMsg);
		}

		case SET_USER_NEW_PASSWORD_CONFIRM: {
			const newpasswordErrorMsg = validatePassword(
				state.newpassword,
				action.value
			);
			return state
				.set("newpasswordConfirm", action.value)
				.set("newpasswordErrorMsg", newpasswordErrorMsg);
		}

		case SET_PASSWORD_CAMP:
			return state
				.set("registerkey", action.value)
				.set("emailOrPseudoErrorMsg", null);

		case SET_SELECTED_CATEGORY:
			return state
				.set("selectedCategory", action.value)
				.set("emailOrPseudoErrorMsg", null)
				.set("passwordErrorMsg", null);

		case CLEAR_VALIDATE:
			return state
				.set("valid", false)
				.set("emailOrPseudoErrorMsg", null)
				.set("passwordErrorMsg", null)
				.set("campsiteErrorMsg", null)
				.set("clearValidate", true);

		case CLEAR_VALIDATE_FB:
			return state
				.set("isFBauthentication", true)
				.set("emailOrPseudoErrorMsg", null)
				.set("passwordErrorMsg", null)
				.set("password", null)
				.set("campsiteErrorMsg", null)
				.set("validFB", false);

		case CLEAR_PASSWORD_VALIDATE:
			return state
				.set("isPwdValid", false)
				.set("passwordErrorMsg", null)
				.set("newpasswordErrorMsg", null)
				.set("isClearPasswordValidated", true);

		case VALIDATE_INPUTS: {
			let emailOrPseudoErrorMsg = validateEmail(state.emailOrPseudo);
			const firstnameErrorMsg =
				state.selectedCategory && !state.firstname
					? t("profile:firstnamemandatory")
					: null;
			const lastnameErrorMsg =
				state.selectedCategory && !state.lastname
					? t("profile:lastnamemandatory")
					: null;
			const passwordErrorMsg =
				!state.selectedCategory && !state.password
					? t("login:missingpassword")
					: null;
			return state
				.set("clearValidate", false)
				.set(
					"valid",
					!emailOrPseudoErrorMsg &&
						!firstnameErrorMsg &&
						!lastnameErrorMsg &&
						!passwordErrorMsg
				)
				.set("emailOrPseudoErrorMsg", emailOrPseudoErrorMsg)
				.set("firstnameErrorMsg", firstnameErrorMsg)
				.set("lastnameErrorMsg", lastnameErrorMsg)
				.set("passwordErrorMsg", passwordErrorMsg);
			// .set(
			//   "campsiteErrorMsg",
			//   state.communityId ? null : t("login:missingcamping")
			// )
		}

		case VALIDATE_FB_INPUTS: {
			let emailOrPseudoErrorMsg = validateEmail(state.emailOrPseudo, false);
			return state
				.set("validFB", emailOrPseudoErrorMsg === null)
				.set("emailOrPseudoErrorMsg", emailOrPseudoErrorMsg);
		}

		case VALIDATE_PASSWORD_INPUTS: {
			let passwordErrorMsg = null;
			if (state.password === null) {
				passwordErrorMsg = t("profile:passwordmandatory");
			}
			const newpasswordErrorMsg = validatePassword(
				state.newpassword,
				state.newpasswordConfirm
			);
			return state
				.set(
					"isPwdValid",
					passwordErrorMsg === null && newpasswordErrorMsg === null
				)
				.set("passwordErrorMsg", passwordErrorMsg)
				.set("newpasswordErrorMsg", newpasswordErrorMsg)
				.set("isClearPasswordValidated", false);
		}

		case SET_REGISTER_PROFESSIONAL_RESULT:
		case SIGNUP_RESULT:
			if (action.payload.message === "New account created") {
				return state
					.set("snackInfoMsg", t("login:popup1"))
					.set("selectedCategory", 0)
					.set("emailOrPseudoErrorMsg", null)
					.set("passwordErrorMsg", null);
			} else {
				return state
					.set("snackInfoMsg", t("login:popup2"))
					.set("selectedCategory", 0)
					.set("emailOrPseudoErrorMsg", null)
					.set("passwordErrorMsg", null);
			}

		case SET_CAMPSITE:
			localStorage.setItem("communityId", action.campsite.value);
			return state
				.set("communityId", action.campsite.value)
				.set("campsiteErrorMsg", null);

		case SET_CAMPSITE_ID:
			return state
				.set("communityId", action.value)
				.set("campsiteErrorMsg", null);

		// case SET_CAMPSITES: {
		//   const values = Object.values(
		//     action.entities.campsites ? action.entities.campsites : []
		//   );
		//   if (!state.communityId && values.length > 0) {
		//     localStorage.setItem("communityId", values[0]._id);
		//     return state.set("communityId", values[0]._id);
		//   }
		//   break;
		// }

		case LOGOUT_RESULT:
			return state.set("logoutResult", action.payload);

		case DELETE_USER_RESULT:
			return state.set("deleteUserResult", action.payload);

		case API_ERROR: {
			if (action.error === "Network Error") {
				return state.set("snackErrorMsg", t("login:networkerror"));
			}
			break;
		}

		case SET_CHANGE_PASSWORD_RESULT: {
			return initialState.set("snackInfoMsg", t("login:passwordchanged"));
		}

		case SET_CHANGE_PASSWORD_FAILURE: {
			const errorMsg =
				action.payload && action.payload.message
					? action.payload.message
					: t("login:unexpectederror");
			if (errorMsg.endsWith("400")) {
				return state.set("passwordErrorMsg", t("login:badpassword"));
			}
			return state.set("snackErroMsg", t("login:unexpectederror"));
		}

		case SET_GENDER:
			return state.set("gender", action.value);

		case SET_FIRST_NAME:
			if (
				!action.value ||
				RegExp("[a-zA-Z- 'çéèêë^¨ÉÈÊaâàù]{1}").test(
					action.value[action.value.length - 1]
				)
			) {
				return state
					.set("firstname", action.value)
					.set("firstnameErrorMsg", null);
			} else {
				return state.set("firstnameErrorMsg", null);
			}

		case SET_LAST_NAME:
			if (
				!action.value ||
				RegExp("[a-zA-Z- 'çéèêë^¨ÉÈÊaâàù]{1}").test(
					action.value[action.value.length - 1]
				)
			) {
				return state
					.set("lastname", action.value.toUpperCase())
					.set("lastnameErrorMsg", null);
			} else {
				return state.set("lastnameErrorMsg", null);
			}

		case SET_REGISTER_PROFESSIONAL_FAILURE:
		case LOGIN_FAILURE: {
			const errorMsg =
				action.payload && action.payload.message
					? action.payload.message
					: t("login:unexpectederror");
			if (errorMsg === "Network Error") {
				break;
			} else if (errorMsg.endsWith("400")) {
				return state.set(
					"emailOrPseudoErrorMsg",
					t("login:alreadyregistereduser")
				);
			} else if (errorMsg.endsWith("401")) {
				const message = action.payload?.response?.data?.message || "";
				if (message.indexOf("jwt") >= 0) {
					return state.set("snackErrorMsg", t("login:tokenexpired"));
				} else if (state.selectedCategory === 0) {
					return state
						.set("emailOrPseudoErrorMsg", t("login:baduserpassword"))
						.set("passwordErrorMsg", t("login:baduserpassword"));
				}
				break;
			} else if (errorMsg.endsWith("403")) {
				return state.set("emailOrPseudoErrorMsg", t("login:unauthorized"));
				// .set("selectedCategory", 1)
			} else if (errorMsg.endsWith("404")) {
				return state.set("snackErrorMsg", t("login:unknown"));
				// .set("selectedCategory", 1)
			} else if (errorMsg.endsWith("406")) {
				return state
					.set("snackInfoMsg", t("login:popup2"))
					.set("selectedCategory", 0);
			} else if (errorMsg.endsWith("423")) {
				return state.set("snackInfoMsg", t("login:popup4"));
			} else {
				return state.set("snackErrorMsg", t("login:unexpectederror"));
			}
		}

		case SET_LOGIN_WITH_FB_RESULT:
			return state
				.set("validForgetPwd", false)
				.set("expires", action.expires)
				.set("permissions", action.permissions)
				.set("declinedPermissions", action.declinedPermissions)
				.set("profileData", action.data)
				.set("emailOrPseudo", action.data.email)
				.set(
					"emailOrPseudoErrorMsg",
					action.data &&
						state.selectedCategory &&
						!action.data.email &&
						!state.emailOrPseudo
						? t("login:missingemail")
						: null
				);

		case SET_LOGIN_WITH_FB_ERROR:
			return state.set("snackErrorMsg", action.error);

		case SET_PROFILE_DETAILS:
			// After login, we reset isFBauthentication
			return state.set("isFBauthentication", false).set("password", null);

		case API_END: {
			switch (action.payload) {
				case FETCH_LOGIN:
				case FETCH_SIGNUP:
				case FETCH_LOGIN_WITH_FB:
				case FETCH_SIGNUP_WITH_FB:
				case FETCH_FORGET_PASSWORD:
				case FETCH_CLEAR_PASSWORD:
				case FETCH_ACTIVATE_ACCOUNT:
				case FETCH_CHANGE_PASSWORD:
				case FETCH_LOGIN_USING_TOKEN:
					return state.set("isFetching", false);
				default:
					break;
			}
			break;
		}

		default:
			return state;
	}
	return state;
}
