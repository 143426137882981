import CommunityPage from "views/Pages/CommunityPage";
import Templates from "views/Pages/Templates/TemplatesPage";
import TemplatesCategories from "views/Pages/TemplatesCategories/TemplatesCategoriesPage";
import ProfilePage from "views/Pages/ProfilePage";
import AboutPage from "views/Pages/AboutPage";
import PasswordPage from "views/Pages/PasswordPage";

import { GiPaperTray } from "react-icons/gi";
import { FaRegNewspaper } from "react-icons/fa";

var dashRoutes = [
	{
		path: "/about",
		name: "À propos",
		component: AboutPage,
		layout: "/team",
		invisible: true
	},
	{
		path: "/community",
		name: "Communauté",
		component: CommunityPage,
		layout: "/team",
		invisible: true
	},
	{
		path: "/profile",
		name: "Profil",
		component: ProfilePage,
		layout: "/team",
		invisible: true
	},
	{
		path: "/change-password",
		name: "Mot de passe",
		component: PasswordPage,
		layout: "/team",
		invisible: true
	},
	{
		path: "/categories",
		name: "Catégories",
		icon: GiPaperTray,
		component: TemplatesCategories.TemplatesCategoriesPage,
		layout: "/team"
	},
	{
		path: "/templates",
		name: "Modèles",
		icon: FaRegNewspaper,
		component: Templates.TemplatesPage,
		layout: "/team"
	}
];
export default dashRoutes;
