import { Component } from "react";
import PropTypes from "prop-types";

import ReactTable from "react-table";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import withStyles from "@material-ui/core/styles/withStyles";

// core components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Snackbar from "components/Snackbar/Snackbar";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardIcon from "components/Card/CardIcon";
import CardHeader from "components/Card/CardHeader";
import CardFooter from "components/Card/CardFooter";
import Button from "components/CustomButtons/Button";
import Activity from "components/DigiHapi/Activity";

// @material-ui/icons
import AddAlert from "@material-ui/icons/AddAlert";

import { VscDebugStepInto } from "react-icons/vsc";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { fetchGetEquipments, fetchReorderEquipments } from "actions/equipment";

import extendedTablesStyle from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle";

import { EQUIPMENTS_ON_DRAG_END } from "actions/types";
import { getSystemEquipments } from "selectors/equipment";

const style = {
	...extendedTablesStyle,
	floatRight: {
		float: "right"
	},
	w100: {
		width: "100%"
	}
};

class DragTrComponent extends Component {
	render() {
		const { children = null, rowInfo, classes } = this.props;
		if (rowInfo && classes) {
			const { original, index } = rowInfo;
			const { _id } = original;
			return (
				<Draggable key={_id} index={index} draggableId={_id}>
					{(draggableProvided, draggableSnapshot) => (
						<div
							className={classes.w100}
							ref={draggableProvided.innerRef}
							{...draggableProvided.draggableProps}
							{...draggableProvided.dragHandleProps}
						>
							<ReactTable.defaultProps.TrComponent className={classes.w100}>
								{children}
							</ReactTable.defaultProps.TrComponent>
							{draggableProvided.placeholder}
						</div>
					)}
				</Draggable>
			);
		} else
			return (
				<ReactTable.defaultProps.TrComponent>
					{children}
				</ReactTable.defaultProps.TrComponent>
			);
	}
}

class DropTbodyComponent extends Component {
	render() {
		const { children = null, classes } = this.props;

		return (
			<Droppable droppableId="droppable">
				{(droppableProvided, droppableSnapshot) => (
					<div
						className={classes.w100}
						ref={droppableProvided.innerRef}
						{...droppableProvided.droppableProps}
					>
						<ReactTable.defaultProps.TbodyComponent>
							{children}
						</ReactTable.defaultProps.TbodyComponent>
						{droppableProvided.placeholder}
					</div>
				)}
			</Droppable>
		);
	}
}

class PlacesOrderPage extends Component {
	state = {
		br: false,
		color: "info",
		message: null
	};

	componentDidMount() {
		const { fetchGetEquipments, community } = this.props;
		fetchGetEquipments(community._id);
	}

	componentDidUpdate(prevProps, prevState) {
		const { fetchGetEquipments, snackErrorMsg, snackInfoMsg, community } =
			this.props;

		if (snackErrorMsg && prevProps.snackErrorMsg !== snackErrorMsg) {
			this.autoCloseMessage();
		}

		if (snackInfoMsg && prevProps.snackInfoMsg !== snackInfoMsg) {
			fetchGetEquipments(community._id);
			this.autoCloseMessage();
		}
	}

	hideAlert = () => {
		this.setState({
			br: false
		});
	};

	autoCloseMessage = () => {
		const { snackErrorMsg, snackInfoMsg } = this.props;

		this.setState({
			br: true,
			message: snackInfoMsg ? snackInfoMsg : snackErrorMsg,
			color: snackInfoMsg ? "info" : "danger"
		});

		if (snackInfoMsg) {
			setTimeout(this.hideAlert, 5000);
		}
	};

	getEquipments = () => {
		const { equipments } = this.props;

		const equipmentsObj = equipments.map(({ _id, name }) => {
			return {
				_id,
				name
			};
		});
		return equipmentsObj;
	};

	handleDragEnd = result => {
		this.props.dispatch({
			type: EQUIPMENTS_ON_DRAG_END,
			result
		});
	};

	getTrProps = (state, rowInfo) => {
		const { classes } = this.props;
		return { rowInfo, classes };
	};

	getTbodyProps = (state, rowInfo, column, rtInstance) => {
		const { classes } = this.props;
		return { rowInfo, column, rtInstance, classes };
	};

	render() {
		const { color, message } = this.state;
		const {
			classes,
			isFetching,
			isFetchingList,
			equipments,
			areEquipmentsReordered,
			fetchReorderEquipments,
			fetchGetEquipments,
			community
		} = this.props;

		return (
			<GridContainer justifyContent="center">
				<GridItem xs={12}>
					<Card>
						<CardHeader icon>
							<CardIcon color="primary">
								<VscDebugStepInto />
							</CardIcon>
							<h4 className={classes.cardIconTitle}>
								Réorganiser les lieux de rendez-vous
							</h4>
						</CardHeader>
						<CardBody>
							{equipments.length && (
								<DragDropContext onDragEnd={this.handleDragEnd}>
									<ReactTable
										TrComponent={DragTrComponent}
										TbodyComponent={DropTbodyComponent}
										getTrProps={this.getTrProps}
										getTbodyProps={this.getTbodyProps}
										data={this.getEquipments()}
										filterable
										columns={[
											{
												Header: "Nom",
												accessor: "name",
												sortable: false,
												filterable: false
											},
											{
												Header: "",
												accessor: "empty",
												sortable: false,
												filterable: false
											}
										]}
										loading={isFetchingList}
										defaultPageSize={equipments.length}
										showPaginationTop={false}
										showPaginationBottom={false}
										previousText="Précédent"
										nextText="Suivant"
										rowsText="Lignes"
										noDataText="Aucuns équipements"
										ofText="sur"
										loadingText="Chargement..."
										className="-striped -highlight"
									/>
								</DragDropContext>
							)}
						</CardBody>
						<CardFooter product>
							<div className={classes.w100}>
								<Button
									color="primary"
									disabled={!areEquipmentsReordered}
									onClick={() => fetchReorderEquipments(equipments)}
									className={classes.floatRight}
								>
									{isFetching ? <Activity /> : "Réorganiser"}
								</Button>
								<Button
									color="brown"
									disabled={!areEquipmentsReordered}
									onClick={() => fetchGetEquipments(community._id)}
									className={classes.floatRight}
								>
									{"Restaurer"}
								</Button>
							</div>
						</CardFooter>
					</Card>
				</GridItem>
				<Snackbar
					place="br"
					color={color}
					icon={AddAlert}
					message={message ? message : ""}
					open={this.state.br}
					closeNotification={() => this.setState({ br: false })}
					close
				/>
			</GridContainer>
		);
	}
}

PlacesOrderPage.propTypes = {
	classes: PropTypes.object
};

const mapStateToProps = state => {
	const {
		isFetchingList,
		isFetching,
		snackInfoMsg,
		snackErrorMsg,
		areEquipmentsReordered
	} = state.equipmentReducer;

	const { community } = state.entitiesReducer;

	return {
		isFetchingList,
		isFetching,
		equipments: Object.values(getSystemEquipments(state)),
		snackInfoMsg,
		snackErrorMsg,
		areEquipmentsReordered,
		community
	};
};

const mapDispatchToProps = dispatch => {
	let actions = bindActionCreators(
		{
			fetchGetEquipments,
			fetchReorderEquipments
		},
		dispatch
	);
	return { ...actions, dispatch };
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withStyles(style)(PlacesOrderPage));
