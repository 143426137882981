import { t } from "services/i18n/index";
import {
	API_END,
	API_START,
	SET_UPLOAD_IMAGES_RESULT,
	SET_SUBFOLDER_IMAGES,
	SET_FILES_IMAGES,
	FETCH_UPLOAD_IMAGES,
	REMOVE_IMAGE,
	CLEAR_UPLOAD_FILE_VALIDATE,
	VALIDATE_UPLOAD_FILE,
	SET_IMAGE_NAME,
	FETCH_GET_CACHED_IMAGES,
	SET_UPLOAD_IMAGES_FAILURE,
	SET_CACHED_IMAGES_FAILURE,
	SET_CACHED_IMAGES,
	FETCH_DELETE_IMAGE,
	SET_DELETE_IMAGE_FAILURE,
	SET_DELETE_IMAGE_RESULT,
	SET_IMAGE_TYPE,
	FETCH_GET_PICTOS_URI,
	SET_PICTOS_URI_FAILURE,
	SET_PICTOS_URI
} from "../actions/types";

const initialState = {
	isFetchingList: false,
	isFetching: false,
	imageErrorMsg: null,
	name: null,
	type: 1,
	nameErrorMsg: null,
	cachedImages: null,
	subFolderName: "cache/sharing",
	uploadImageResult: null,
	files: [],
	clearValidate: false,
	isValid: false,
	snackInfoMsg: null,
	snackErrorMsg: null,
	pictos: null,
	types: {
		1: {
			type: 1,
			label: "Instants partage",
			subFolderName: "cache/sharing"
		},
		2: {
			type: 2,
			label: "Instants partage HapiColibri",
			subFolderName: "cache/HapiColibri/sharing"
		},
		3: {
			type: 3,
			label: "Accueil O’Camping",
			subFolderName: "cache/OCamping/home"
		},
		4: {
			type: 4,
			label: "Accueil HapiColibri",
			subFolderName: "cache/HapiColibri/home"
		},
		5: {
			type: 5,
			label: "Catégories d'annonces",
			subFolderName: "cache/adCategories"
		},
		6: {
			type: 6,
			label: "Météo",
			subFolderName: "cache/weather"
		},
		7: {
			type: 7,
			label: "Autres",
			subFolderName: "cache/others"
		},
		8: {
			type: 8,
			label: "Accueil HapiCity",
			subFolderName: "cache/HapiCity/home"
		}
	}
};

export default function uploadReducer(state = initialState, action = {}) {
	switch (action.type) {
		case API_START:
			if (
				action.payload === FETCH_GET_CACHED_IMAGES ||
				action.payload === FETCH_GET_PICTOS_URI
			) {
				return {
					...state,
					isFetchingList: true,
					snackInfoMsg: null,
					snackErrorMsg: null
				};
			}
			if (
				action.payload === FETCH_UPLOAD_IMAGES ||
				action.payload === FETCH_DELETE_IMAGE
			) {
				return {
					...state,
					isFetching: true,
					snackInfoMsg: null,
					snackErrorMsg: null
				};
			}
			break;

		case CLEAR_UPLOAD_FILE_VALIDATE:
			return {
				...state,
				imageErrorMsg: null,
				nameErrorMsg: null,
				isValid: false,
				clearValidate: true
			};

		case VALIDATE_UPLOAD_FILE:
			const imageErrorMsg =
				state.files && state.files.length ? null : t("template:imagemandatory");
			const nameErrorMsg =
				state.name && state.name.trim().length > 0
					? null
					: t("template:namemandatory");
			return {
				...state,
				imageErrorMsg,
				nameErrorMsg,
				clearValidate: false,
				isValid: !imageErrorMsg && !nameErrorMsg
			};

		case SET_IMAGE_NAME:
			return {
				...state,
				name: action.payload
			};

		case SET_IMAGE_TYPE:
			const { type, subFolderName } = action.payload;
			return {
				...state,
				type,
				subFolderName
			};

		case SET_SUBFOLDER_IMAGES:
			return {
				...state,
				subFolderName: action.payload
			};

		case SET_FILES_IMAGES:
			return {
				...state,
				files: action.payload,
				name: action.payload[0].name
			};

		case REMOVE_IMAGE:
			return {
				...state,
				files: []
			};

		case SET_PICTOS_URI:
			return {
				...state,
				pictos: action.payload
			};

		case SET_CACHED_IMAGES:
			return {
				...state,
				cachedImages: action.payload
			};

		case SET_DELETE_IMAGE_RESULT:
		case SET_UPLOAD_IMAGES_RESULT:
			return {
				...initialState,
				isFetching: state.isFetching,
				type: state.type,
				subFolderName: state.subFolderName,
				pictos: null,
				snackInfoMsg: "ok"
			};

		case SET_PICTOS_URI_FAILURE:
		case SET_DELETE_IMAGE_FAILURE:
		case SET_CACHED_IMAGES_FAILURE:
		case SET_UPLOAD_IMAGES_FAILURE:
			return {
				...state,
				snackErrorMsg: "ko"
			};

		case API_END:
			if (
				action.payload === FETCH_GET_CACHED_IMAGES ||
				action.payload === FETCH_GET_PICTOS_URI
			) {
				return {
					...state,
					isFetchingList: false
				};
			}
			if (
				action.payload === FETCH_UPLOAD_IMAGES ||
				action.payload === FETCH_DELETE_IMAGE
			) {
				return {
					...state,
					isFetching: false
				};
			}
			break;

		default:
			return state;
	}
	return state;
}
