import { FETCH_GET_AUDITS, SET_AUDITS, SET_AUDITS_FAILURE } from "./types";
import { apiAction } from "./api";
import { schema } from "normalizr";

const user = new schema.Entity(
	"users",
	{},
	{
		idAttribute: "_id"
	}
);

const community = new schema.Entity(
	"communities",
	{},
	{
		idAttribute: "_id"
	}
);

const audits = new schema.Entity(
	"audits",
	{
		communityId: community,
		userId: user
	},
	{
		idAttribute: "_id"
	}
);

const infospage = new schema.Entity("infospages", {
	docs: [audits]
});

export function fetchGetAudits(state) {
	return apiAction({
		url:
			"/audits?page=" +
			(state.page + 1) +
			"&pageSize=" +
			state.pageSize +
			(state.sorted.length > 0
				? "&sorted=" +
				  state.sorted.map(elt => elt.id + (elt.desc ? ":-1" : ":1")).join("|")
				: ""),
		method: "GET",
		schema: infospage,
		onSuccess: setAudits,
		onFailure: setAuditsFailure,
		label: FETCH_GET_AUDITS
	});
}

function setAudits(normalized) {
	return {
		type: SET_AUDITS,
		entities: normalized.entities
	};
}

function setAuditsFailure(data) {
	return {
		type: SET_AUDITS_FAILURE,
		payload: data
	};
}
