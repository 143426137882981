// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import FormHelperText from "@material-ui/core/FormHelperText";

// core components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import PropTypes from "prop-types";
import { Component } from "react";
import { connect } from "react-redux";
import {
	ADD_GOODDEAL_CITY_CHANNEL,
	DELETE_GOODDEAL_CITY_CHANNEL
} from "actions/types.js";
import customInputStyle from "assets/jss/material-dashboard-pro-react/components/customInputStyle";
import { fetchSearchGooddealCityChannels } from "actions/channel";
import { bindActionCreators } from "redux";
import ReactTags from "react-tag-autocomplete";

const style = {
	infoText: {
		fontWeight: "300",
		margin: "10px 0 30px",
		textAlign: "center"
	},
	tags: {
		margin: "20px",
		width: "100%"
	},
	...customInputStyle
};

class Step6 extends Component {
	sendState() {
		return this.state;
	}

	handleDelete = index => {
		const { dispatch } = this.props;

		if (index >= 0) {
			dispatch({
				type: DELETE_GOODDEAL_CITY_CHANNEL,
				index
			});
		}
	};

	handleAddition = channel => {
		const { dispatch } = this.props;
		dispatch({
			type: ADD_GOODDEAL_CITY_CHANNEL,
			value: channel
		});
	};

	onInput = query => {
		if (query?.length > 2) {
			this.props.fetchSearchGooddealCityChannels(query);
		}
	};

	render() {
		const {
			classes,
			hapicity_channels,
			hapicity_channels_suggestions,
			publishedChannelErrorMsg
		} = this.props;

		return (
			<GridContainer justifyContent="center">
				<GridItem xs={12} sm={12}>
					<h4 className={classes.infoText}>
						{
							"Veuillez sélectionner la/les villes où publier votre annonce sur HapiCity"
						}
					</h4>
				</GridItem>
				<GridItem xs={12} sm={12}>
					<ReactTags
						placeholderText="Villes ..."
						tags={hapicity_channels}
						suggestions={hapicity_channels_suggestions}
						minQueryLength={3}
						maxSuggestionsLength={10}
						onDelete={this.handleDelete}
						onAddition={this.handleAddition}
						suggestionsFilter={() => true}
						onInput={this.onInput}
						removeButtonText="Cliquez pour retirer"
						inputAttributes={{
							autoComplete: "new-password"
						}}
					/>
					{publishedChannelErrorMsg && (
						<FormHelperText className={classes.labelRootError}>
							{publishedChannelErrorMsg}
						</FormHelperText>
					)}
				</GridItem>
			</GridContainer>
		);
	}
}

Step6.propTypes = {
	classes: PropTypes.object
};

const mapStateToProps = state => {
	const {
		isFetching,
		publishedCities,
		publishedCityErrorMsg,
		suggestions,
		hapicity_channels,
		hapicity_channels_suggestions,
		publishedChannelErrorMsg
	} = state.gooddealReducer;
	return {
		suggestions,
		publishedCities,
		publishedCityErrorMsg,
		isFetching,
		hapicity_channels: Object.values(hapicity_channels),
		hapicity_channels_suggestions: Object.values(hapicity_channels_suggestions),
		publishedChannelErrorMsg
	};
};

const mapDispatchToProps = dispatch => {
	let actions = bindActionCreators(
		{
			fetchSearchGooddealCityChannels
		},
		dispatch
	);
	return { ...actions, dispatch };
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withStyles(style)(Step6));
