import { Component } from "react";
import PropTypes from "prop-types";

import ReactTable from "react-table";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import withStyles from "@material-ui/core/styles/withStyles";

// core components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Snackbar from "components/Snackbar/Snackbar";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardIcon from "components/Card/CardIcon";
import CardHeader from "components/Card/CardHeader";
import CardFooter from "components/Card/CardFooter";
import Button from "components/CustomButtons/Button";
import Activity from "components/DigiHapi/Activity";

// @material-ui/icons
import AddAlert from "@material-ui/icons/AddAlert";

import { GiBarracksTent } from "react-icons/gi";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { fetchGetLocations, fetchReorderLocations } from "actions/location";

import extendedTablesStyle from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle";
import commonStyles from "assets/jss/DigiHapi/commonStyles";

import { LOCATIONS_ON_DRAG_END } from "actions/types.js";

const style = {
	...extendedTablesStyle,
	...commonStyles,
	container: {
		maxHeight: "calc(100vh - 400px)",
		overflow: "auto"
	}
};

class DragTrComponent extends Component {
	render() {
		const { children = null, rowInfo, classes } = this.props;
		if (rowInfo && classes) {
			const { original, index } = rowInfo;
			const { _id } = original;
			return (
				<Draggable key={_id} index={index} draggableId={_id}>
					{(draggableProvided, draggableSnapshot) => (
						<div
							className={classes.w100}
							ref={draggableProvided.innerRef}
							{...draggableProvided.draggableProps}
							{...draggableProvided.dragHandleProps}
						>
							<ReactTable.defaultProps.TrComponent className={classes.w100}>
								{children}
							</ReactTable.defaultProps.TrComponent>
							{draggableProvided.placeholder}
						</div>
					)}
				</Draggable>
			);
		} else
			return (
				<ReactTable.defaultProps.TrComponent>
					{children}
				</ReactTable.defaultProps.TrComponent>
			);
	}
}

class DropTbodyComponent extends Component {
	render() {
		const { children = null, classes } = this.props;

		return (
			<Droppable droppableId="droppable">
				{(droppableProvided, droppableSnapshot) => (
					<div
						className={classes.w100}
						ref={droppableProvided.innerRef}
						{...droppableProvided.droppableProps}
					>
						<ReactTable.defaultProps.TbodyComponent>
							{children}
						</ReactTable.defaultProps.TbodyComponent>
						{droppableProvided.placeholder}
					</div>
				)}
			</Droppable>
		);
	}
}

class LocationsOrderPage extends Component {
	state = {
		br: false,
		color: "info",
		message: null
	};

	getLocations = () => {
		const { fetchGetLocations, community } = this.props;
		fetchGetLocations(community._id);
	};

	componentDidMount() {
		this.getLocations();
	}

	componentDidUpdate(prevProps, prevState) {
		const { snackErrorMsg, snackInfoMsg } = this.props;

		if (snackErrorMsg && prevProps.snackErrorMsg !== snackErrorMsg) {
			this.autoCloseMessage();
		}

		if (snackInfoMsg && prevProps.snackInfoMsg !== snackInfoMsg) {
			this.getLocations();
			this.autoCloseMessage();
		}
	}

	hideAlert = () => {
		this.setState({
			br: false
		});
	};

	autoCloseMessage = () => {
		const { snackErrorMsg, snackInfoMsg } = this.props;

		this.setState({
			br: true,
			message: snackInfoMsg ? snackInfoMsg : snackErrorMsg,
			color: snackInfoMsg ? "info" : "danger"
		});

		if (snackInfoMsg) {
			setTimeout(this.hideAlert, 5000);
		}
	};

	getLocationsData = () => {
		const { locations } = this.props;

		const locationsObj = locations.map(({ _id, name }) => {
			return {
				_id,
				name
			};
		});
		return locationsObj;
	};

	handleDragEnd = result => {
		this.props.dispatch({
			type: LOCATIONS_ON_DRAG_END,
			result
		});
	};

	getTrProps = (state, rowInfo) => {
		const { classes } = this.props;
		return { rowInfo, classes };
	};

	getTbodyProps = (state, rowInfo, column, rtInstance) => {
		const { classes } = this.props;
		return { rowInfo, column, rtInstance, classes };
	};

	render() {
		const { color, message } = this.state;
		const {
			classes,
			isFetching,
			isFetchingList,
			locations,
			areLocationsReordered,
			fetchReorderLocations
		} = this.props;

		return (
			<GridContainer justifyContent="center">
				<GridItem xs={12}>
					<Card noaos>
						<CardHeader color="primary" icon>
							<CardIcon color="primary">
								<GiBarracksTent />
							</CardIcon>
							<h4 className={classes.cardIconTitle}>
								Réorganiser les hébergements
							</h4>
						</CardHeader>
						<CardBody>
							{locations.length && (
								<DragDropContext onDragEnd={this.handleDragEnd}>
									<div className={classes.container}>
										<ReactTable
											TrComponent={DragTrComponent}
											TbodyComponent={DropTbodyComponent}
											getTrProps={this.getTrProps}
											getTbodyProps={this.getTbodyProps}
											data={this.getLocationsData()}
											filterable
											columns={[
												{
													Header: "Nom",
													accessor: "name",
													sortable: false,
													filterable: false
												},
												{
													Header: "",
													accessor: "empty",
													sortable: false,
													filterable: false
												}
											]}
											loading={isFetchingList}
											defaultPageSize={locations.length}
											showPaginationTop={false}
											showPaginationBottom={false}
											noDataText="Aucuns hébergements"
											loadingText="Chargement..."
										/>
									</div>
								</DragDropContext>
							)}
						</CardBody>
						<CardFooter product>
							<div className={classes.w100}>
								<Button
									color="primary"
									disabled={!areLocationsReordered}
									onClick={() => fetchReorderLocations(locations)}
									className={classes.floatRight}
								>
									{isFetching ? <Activity /> : "Réorganiser"}
								</Button>
								<Button
									color="brown"
									disabled={!areLocationsReordered}
									onClick={this.getLocations}
									className={classes.floatRight}
								>
									{"Restaurer"}
								</Button>
							</div>
						</CardFooter>
					</Card>
				</GridItem>
				<Snackbar
					place="br"
					color={color}
					icon={AddAlert}
					message={message ? message : ""}
					open={this.state.br}
					closeNotification={() => this.setState({ br: false })}
					close
				/>
			</GridContainer>
		);
	}
}

LocationsOrderPage.propTypes = {
	classes: PropTypes.object
};

const mapStateToProps = state => {
	const {
		isFetchingList,
		isFetching,
		snackInfoMsg,
		snackErrorMsg,
		locations,
		areLocationsReordered
	} = state.locationReducer;

	const { community } = state.entitiesReducer;

	return {
		isFetchingList,
		isFetching,
		locations: Object.values(locations),
		snackInfoMsg,
		snackErrorMsg,
		areLocationsReordered,
		community
	};
};

const mapDispatchToProps = dispatch => {
	let actions = bindActionCreators(
		{
			fetchGetLocations,
			fetchReorderLocations
		},
		dispatch
	);
	return { ...actions, dispatch };
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withStyles(style)(LocationsOrderPage));
