import withStyles from "@material-ui/core/styles/withStyles";

import { confirmAlert } from "react-confirm-alert";

// @material-ui/icons
import Add from "@material-ui/icons/Add";
import AddAlert from "@material-ui/icons/AddAlert";
import Edit from "@material-ui/icons/Edit";
import Delete from "@material-ui/icons/Delete";
import WhereToVote from "@material-ui/icons/WhereToVote";
import { FaCampground } from "react-icons/fa";
import {
	fetchGetCampsiteDetails,
	fetchGetCampsitesNames,
	fetchDeleteCampsite,
	fetchAddCampsite,
	fetchUpdateCampsite
} from "actions/campsite";
import { fetchGetCommunityDetails } from "actions/community";
import {
	RESET_CAMPSITE,
	SET_CAMPSITES_NAME_FILTER,
	SET_CAMPSITES_SORTED,
	SET_DELETE_CAMPSITE_CONFIRMATION_NAME
} from "actions/types";

// core components
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import CardFooter from "components/Card/CardFooter";
import CardIcon from "components/Card/CardIcon";
import Button from "components/CustomButtons/Button";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import CustomInput from "components/CustomInput/CustomInput";

// DigiHapi components
import SnackBarContainer from "components/DigiHapi/SnackBarContainer";
import Wizard from "components/DigiHapi/Wizard";

import extendedTablesStyle from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle";
import { beigeColor } from "assets/jss/material-dashboard-pro-react";

// core components
import PropTypes from "prop-types";
import { Component } from "react";
import { connect } from "react-redux";
import ReactTable from "react-table";
import { bindActionCreators } from "redux";
import Components from "./CampsitesPage";
import Step1 from "./Step1";
import Step2 from "./Step2";
import Step3 from "./Step3";

const style = {
	...extendedTablesStyle,
	customUI: {
		backgroundColor: beigeColor[1],
		maxWidth: "400px",
		borderRadius: "10px",
		padding: 10
	},
	w100: {
		width: "100%"
	},
	floatRight: {
		float: "right"
	},
	justifyContentSpaceAround: {
		display: "flex",
		justifyContent: "space-around"
	},
	descriptionContainer: {
		height: "60px"
	}
};

class CampsitesWizard extends Component {
	fetchAddCampsite = () => {
		const {
			fetchAddCampsite,
			name,
			stars,
			customUri,
			address,
			zipCode,
			city,
			webSiteUrl,
			mail,
			loc,
			phone,
			fax,
			registerkey,
			files,
			channels
		} = this.props;

		fetchAddCampsite({
			name,
			customUri,
			stars,
			address,
			zipCode,
			city,
			webSiteUrl,
			mail,
			loc,
			phone,
			fax,
			registerkey,
			files,
			channelsIds: Object.values(channels)
		});
	};

	fetchUpdateCampsite = () => {
		const {
			fetchUpdateCampsite,
			_id,
			name,
			customUri,
			stars,
			registerkey,
			address,
			zipCode,
			city,
			webSiteUrl,
			mail,
			loc,
			phone,
			fax,
			files,
			logoUri,
			mapUri,
			mapSmallUri,
			equipmentsIds,
			channels
		} = this.props;

		fetchUpdateCampsite({
			_id,
			name,
			customUri,
			stars,
			registerkey,
			address,
			zipCode,
			city,
			webSiteUrl,
			mail,
			loc,
			phone,
			fax,
			files,
			logoUri,
			mapUri,
			mapSmallUri,
			equipmentsIds,
			channelsIds: Object.values(channels)
		});
	};

	render() {
		const { update, isFetching, onClose } = this.props;
		return (
			<GridItem xs={12} sm={12}>
				<Wizard
					onClose={onClose}
					isFetching={isFetching}
					validate
					steps={[
						{
							stepName: "Illustration",
							stepComponent: Step1,
							stepId: "picture"
						},
						{
							stepName: "Informations",
							stepComponent: Step2,
							stepId: "infos"
						},
						{
							stepName: "Localisation",
							stepComponent: Step3,
							stepId: "localisation"
						}
					]}
					title={update ? "Mise à jour d'un camping" : "Ajout d'un camping"}
					subtitle=""
					finishButtonClick={
						update ? this.fetchUpdateCampsite : this.fetchAddCampsite
					}
				/>
			</GridItem>
		);
	}
}

class CampsitesPage extends Component {
	state = {
		wizard: null,
		alert: null,
		name: ""
	};

	componentDidMount() {
		this.props.fetchGetCampsitesNames();
	}

	componentDidUpdate(prevProps, prevState) {
		const {
			fetchGetCampsitesNames,
			snackInfoMsg,
			newWizard,
			page,
			limit,
			sorted
		} = this.props;

		if (snackInfoMsg && prevProps.snackInfoMsg !== snackInfoMsg) {
			this.setState({
				wizard: null
			});
			fetchGetCampsitesNames(page, limit, sorted);
		}

		if (newWizard && prevProps.newWizard !== newWizard) {
			this.addWizard(newWizard === "update");
		}
	}

	onClose = () => {
		this.setState({
			wizard: null
		});
		this.props.dispatch({
			type: RESET_CAMPSITE
		});
	};

	addWizard = (update = true) => {
		this.setState({
			wizard: (
				<Components.CampsitesWizard update={update} onClose={this.onClose} />
			)
		});
	};

	changeCommunity = id => {
		this.props.fetchGetCommunityDetails(id);
	};

	editCampsite = id => {
		this.props.fetchGetCampsiteDetails(id);
	};

	deleteCampsite = id => {
		this.props.fetchDeleteCampsite(id, this.props.deleteConfirmationName);
	};

	getRoundButtons = (id, name) => {
		const { classes } = this.props;
		return [
			{ color: "success", icon: WhereToVote, onClick: this.changeCommunity },
			{ color: "brown", icon: Edit, onClick: this.editCampsite },
			{ color: "danger", icon: Delete, onClick: this.delete }
		].map((prop, key) => {
			return (
				<Button
					round
					color={prop.color}
					className={classes.actionButton + " " + classes.actionButtonRound}
					key={key}
					disabled={this.state.wizard ? true : false}
					onClick={() => prop.onClick(id, name)}
				>
					<prop.icon className={classes.icon} />
				</Button>
			);
		});
	};

	getCampsites = () => {
		const { campsitesNames } = this.props;

		const campsitesObj = Object.values(campsitesNames).map(({ _id, name }) => {
			return {
				name,
				actions: (
					<div className="actions-right">{this.getRoundButtons(_id, name)}</div>
				)
			};
		});
		return campsitesObj;
	};

	delete = (communityId, campsiteName) => {
		const { classes, dispatch } = this.props;
		confirmAlert({
			customUI: ({ onClose }) => {
				return (
					<div className={classes.customUI}>
						<h2>Confirmation</h2>
						<p>Êtes-vous sûr de vouloir supprimer le camping ?</p>
						{`Confirmer la suppression du camping en saisisant le nom du camping ${campsiteName}`}
						<CustomInput
							labelText="Camping ..."
							id="camping"
							formControlProps={{
								fullWidth: true
							}}
							inputProps={{
								// value: deleteConfirmationName,
								onChange: e =>
									dispatch({
										type: SET_DELETE_CAMPSITE_CONFIRMATION_NAME,
										value: e.target.value
									})
							}}
						/>
						<div className={classes.justifyContentSpaceAround}>
							<Button
								onClick={() => {
									onClose();
								}}
							>
								Annuler
							</Button>
							<Button
								color="danger"
								onClick={() => {
									this.deleteCampsite(communityId);
									onClose();
								}}
							>
								Supprimer
							</Button>
						</div>
					</div>
				);
			}
		});
	};

	render() {
		const { alert } = this.state;
		const {
			classes,
			isFetchingList,
			dispatch,
			page,
			totalPages,
			limit,
			sorted,
			fetchGetCampsitesNames,
			nameFilter,
			snackInfoMsg,
			snackErrorMsg
		} = this.props;

		return (
			<SnackBarContainer
				snackInfoMsg={snackInfoMsg}
				snackErrorMsg={snackErrorMsg}
			>
				<GridContainer justifyContent="center">
					{alert}
					<GridItem xs={12}>
						<Card>
							<CardHeader icon>
								<CardIcon color="primary">
									<FaCampground />
								</CardIcon>
								<h4 className={classes.cardIconTitle}>Campings</h4>
							</CardHeader>
							<CardBody>
								<ReactTable
									data={this.getCampsites()}
									filterable
									columns={[
										{
											Header: "Nom",
											accessor: "name",
											sortable: true,
											filterable: true
										},
										{
											Header: "Actions",
											accessor: "actions",
											sortable: false,
											filterable: false
										}
									]}
									showPaginationTop
									showPaginationBottom={false}
									manual
									manualFilters
									onFetchData={state => {
										if (
											page !== state.page + 1 ||
											limit !== state.pageSize ||
											sorted !== state.sorted ||
											nameFilter !== state.filtered?.[0]?.value
										) {
											dispatch({
												type: SET_CAMPSITES_SORTED,
												value: state.sorted
											});
											dispatch({
												type: SET_CAMPSITES_NAME_FILTER,
												value: state.filtered?.[0]?.value
											});
											fetchGetCampsitesNames(
												state.page + 1,
												state.pageSize,
												state.sorted,
												state.filtered?.[0]?.value
											);
										}
									}}
									loading={isFetchingList}
									defaultPageSize={limit}
									defaultSorted={sorted}
									page={page - 1}
									pageSizeOptions={[5, 10, 15, 20, 40, 80]}
									pages={totalPages}
									previousText="Précédent"
									nextText="Suivant"
									rowsText="Lignes"
									noDataText="Aucuns campings"
									ofText="sur"
									loadingText="Chargement..."
									className="-striped -highlight"
								/>
							</CardBody>
							<CardFooter product>
								<div className={classes.w100}>
									<Button
										className={classes.floatRight}
										color="primary"
										onClick={() => this.addWizard(false)}
										disabled={Boolean(this.state.wizard)}
									>
										<Add /> {"Ajout d'un camping"}
									</Button>
								</div>
							</CardFooter>
						</Card>
					</GridItem>
					{this.state.wizard}
				</GridContainer>
			</SnackBarContainer>
		);
	}
}

CampsitesPage.propTypes = {
	classes: PropTypes.object
};

const mapStateToProps = state => {
	const {
		_id,
		isFetching,
		isFetchingList,
		name,
		address,
		zipCode,
		city,
		webSiteUrl,
		mail,
		loc,
		phone,
		fax,
		registerkey,
		files,
		snackInfoMsg,
		snackErrorMsg,
		logoUri,
		mapUri,
		mapSmallUri,
		newWizard,
		equipmentsIds,
		deleteConfirmationName,
		channels,
		citiesTags,
		customUri,
		campsitesNames,
		page,
		hasNextPage,
		nextPage,
		totalPages,
		totalDocs,
		limit,
		sorted,
		nameFilter,
		stars
	} = state.campsiteReducer;

	return {
		_id,
		isFetching,
		isFetchingList,
		name,
		address,
		zipCode,
		city,
		webSiteUrl,
		mail,
		loc,
		phone,
		fax,
		registerkey,
		files,
		snackInfoMsg,
		snackErrorMsg,
		logoUri,
		mapUri,
		mapSmallUri,
		newWizard,
		equipmentsIds,
		deleteConfirmationName,
		channels,
		citiesTags,
		customUri,
		campsitesNames,
		page,
		hasNextPage,
		nextPage,
		totalPages,
		totalDocs,
		limit,
		sorted,
		nameFilter,
		stars
	};
};

const mapDispatchToProps = dispatch => {
	let actions = bindActionCreators(
		{
			fetchGetCampsitesNames,
			fetchGetCampsiteDetails,
			fetchDeleteCampsite,
			fetchAddCampsite,
			fetchUpdateCampsite,
			fetchGetCommunityDetails
		},
		dispatch
	);
	return { ...actions, dispatch };
};

export default {
	CampsitesPage: connect(
		mapStateToProps,
		mapDispatchToProps
	)(withStyles(style)(CampsitesPage)),
	CampsitesWizard: connect(
		mapStateToProps,
		mapDispatchToProps
	)(withStyles(style)(CampsitesWizard))
};
