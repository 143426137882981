import {
	FETCH_GET_CURRENT_COMMUNITY_DETAILS,
	SET_CURRENT_COMMUNITY_DETAILS_RESULT,
	SET_CURRENT_COMMUNITY_DETAILS_FAILURE,
	FETCH_GET_COMMUNITY_DETAILS,
	SET_COMMUNITY_DETAILS_RESULT,
	SET_COMMUNITY_DETAILS_FAILURE,
	FETCH_REORDER_COMMUNITY_EQUIPMENTS,
	SET_REORDER_COMMUNITY_EQUIPMENTS_RESULT,
	SET_REORDER_COMMUNITY_EQUIPMENTS_FAILURE
} from "./types";
import { apiAction } from "./api";
import { schema } from "normalizr";

const equipment = new schema.Entity(
	"equipments",
	{},
	{
		idAttribute: "_id"
	}
);

export const community = new schema.Entity(
	"community",
	{
		equipmentsIds: [equipment]
	},
	{
		idAttribute: "_id"
	}
);

export function fetchGetCurrentCommunityDetails() {
	return apiAction({
		url: "/communities/community",
		method: "GET",
		schema: community,
		onSuccess: setCurrentCommunityDetailsResult,
		onFailure: setCurrentCommunityDetailsFailure,
		label: FETCH_GET_CURRENT_COMMUNITY_DETAILS
	});
}

function setCurrentCommunityDetailsResult(normalized) {
	return {
		type: SET_CURRENT_COMMUNITY_DETAILS_RESULT,
		entities: normalized.entities
	};
}

function setCurrentCommunityDetailsFailure(error) {
	return {
		type: SET_CURRENT_COMMUNITY_DETAILS_FAILURE,
		error
	};
}

export function fetchGetCommunityDetails(id) {
	return apiAction({
		url: "/communities/community/" + id,
		method: "GET",
		schema: community,
		onSuccess: setCommunityDetailsResult,
		onFailure: setCommunityDetailsFailure,
		label: FETCH_GET_COMMUNITY_DETAILS
	});
}

function setCommunityDetailsResult(normalized) {
	return {
		type: SET_COMMUNITY_DETAILS_RESULT,
		entities: normalized.entities
	};
}

function setCommunityDetailsFailure(error) {
	return {
		type: SET_COMMUNITY_DETAILS_FAILURE,
		error
	};
}

export function fetchReorderCommunityEquipments(communityId, equipmentsIds) {
	return apiAction({
		url: "/communities/community/" + communityId + "/equipments",
		method: "PUT",
		data: { equipmentsIds },
		onSuccess: setReorderCommunityEquipmentsResult,
		onFailure: setReorderCommunityEquipmentsFailure,
		label: FETCH_REORDER_COMMUNITY_EQUIPMENTS
	});
}

function setReorderCommunityEquipmentsResult(data) {
	return {
		type: SET_REORDER_COMMUNITY_EQUIPMENTS_RESULT,
		payload: data
	};
}

function setReorderCommunityEquipmentsFailure(error) {
	return {
		type: SET_REORDER_COMMUNITY_EQUIPMENTS_FAILURE,
		error
	};
}
