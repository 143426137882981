import {
	FETCH_GET_GOODDEAL_CHANNELS,
	SET_GOODDEAL_CHANNELS,
	SET_GOODDEAL_CHANNELS_FAILURE,
	SET_CHANNELS_FAILURE,
	FETCH_SEARCH_GOODDEAL_CITY_CHANNELS,
	SET_GOODDEAL_CITY_CHANNELS,
	FETCH_SEARCH_GOODDEAL_CAMPSITE_CITY_CHANNELS,
	SET_GOODDEAL_CAMPSITE_CITY_CHANNELS,
	FETCH_SEARCH_GOODDEAL_CAMPSITE_CHANNELS,
	SET_GOODDEAL_CAMPSITE_CHANNELS,
	FETCH_SEARCH_GOODDEAL_NEIGHBORHOOD_CITY_CHANNELS,
	SET_GOODDEAL_NEIGHBORHOOD_CITY_CHANNELS,
	FETCH_SEARCH_GOODDEAL_NEIGHBORHOOD_CHANNELS,
	SET_GOODDEAL_NEIGHBORHOOD_CHANNELS,
	FETCH_SEARCH_EVENT_CITY_CHANNELS,
	SET_EVENT_CITY_CHANNELS,
	FETCH_SEARCH_EVENT_CAMPSITE_CITY_CHANNELS,
	SET_EVENT_CAMPSITE_CITY_CHANNELS,
	FETCH_SEARCH_EVENT_CAMPSITE_CHANNELS,
	SET_EVENT_CAMPSITE_CHANNELS,
	FETCH_SEARCH_EVENT_NEIGHBORHOOD_CITY_CHANNELS,
	SET_EVENT_NEIGHBORHOOD_CITY_CHANNELS,
	FETCH_SEARCH_EVENT_NEIGHBORHOOD_CHANNELS,
	SET_EVENT_NEIGHBORHOOD_CHANNELS,
	FETCH_SEARCH_CITY_CHANNELS,
	SET_CITY_CHANNELS,
	FETCH_SEARCH_CAMPSITE_CITY_CHANNELS,
	SET_CAMPSITE_CITY_CHANNELS,
	FETCH_SEARCH_NEIGHBORHOOD_CITY_CHANNELS,
	SET_NEIGHBORHOOD_CITY_CHANNELS
} from "./types";
import { apiAction } from "./api";
import { schema } from "normalizr";

const channels = new schema.Entity(
	"channels",
	{},
	{
		idAttribute: "_id"
	}
);

function setChannelsFailure(data) {
	return {
		type: SET_CHANNELS_FAILURE,
		payload: data
	};
}

export function fetchSearchCityChannels(name, ignoreWeather) {
	return apiAction({
		url: "/channels/search",
		method: "GET",
		data: {
			name,
			appname: "HapiCity",
			ignoreWeather: ignoreWeather ? ignoreWeather : undefined
		},
		schema: [channels],
		onSuccess: setCityChannels,
		onFailure: setChannelsFailure,
		label: FETCH_SEARCH_CITY_CHANNELS
	});
}

function setCityChannels(normalized) {
	return {
		type: SET_CITY_CHANNELS,
		entities: normalized.entities
	};
}

export function fetchSearchCampsiteCityChannels(name, ignoreWeather) {
	return apiAction({
		url: "/channels/search",
		method: "GET",
		data: {
			name,
			appname: "OCamping",
			ignoreWeather: ignoreWeather ? ignoreWeather : undefined
		},
		schema: [channels],
		onSuccess: setCampsiteCityChannels,
		onFailure: setChannelsFailure,
		label: FETCH_SEARCH_CAMPSITE_CITY_CHANNELS
	});
}

function setCampsiteCityChannels(normalized) {
	return {
		type: SET_CAMPSITE_CITY_CHANNELS,
		entities: normalized.entities
	};
}

export function fetchSearchNeighborhoodCityChannels(name, ignoreWeather) {
	return apiAction({
		url: "/channels/search",
		method: "GET",
		data: {
			name,
			appname: "HapiColibri",
			ignoreWeather: ignoreWeather ? ignoreWeather : undefined
		},
		schema: [channels],
		onSuccess: setNeighborhoodCityChannels,
		onFailure: setChannelsFailure,
		label: FETCH_SEARCH_NEIGHBORHOOD_CITY_CHANNELS
	});
}

function setNeighborhoodCityChannels(normalized) {
	return {
		type: SET_NEIGHBORHOOD_CITY_CHANNELS,
		entities: normalized.entities
	};
}

export function fetchSearchGooddealCityChannels(name) {
	return apiAction({
		url: "/channels/search",
		method: "GET",
		data: {
			name,
			channeltype: "CityGooddealChannel",
			appname: "HapiCity"
		},
		schema: [channels],
		onSuccess: setGooddealCityChannels,
		onFailure: setChannelsFailure,
		label: FETCH_SEARCH_GOODDEAL_CITY_CHANNELS
	});
}

function setGooddealCityChannels(normalized) {
	return {
		type: SET_GOODDEAL_CITY_CHANNELS,
		entities: normalized.entities
	};
}

export function fetchSearchGooddealCampsiteCityChannels(name) {
	return apiAction({
		url: "/channels/search",
		method: "GET",
		data: {
			name,
			channeltype: "CityGooddealChannel",
			appname: "OCamping"
		},
		schema: [channels],
		onSuccess: setGooddealCampsiteCityChannels,
		onFailure: setChannelsFailure,
		label: FETCH_SEARCH_GOODDEAL_CAMPSITE_CITY_CHANNELS
	});
}

function setGooddealCampsiteCityChannels(normalized) {
	return {
		type: SET_GOODDEAL_CAMPSITE_CITY_CHANNELS,
		entities: normalized.entities
	};
}

export function fetchSearchGooddealCampsiteChannels(name) {
	return apiAction({
		url: "/channels/search",
		method: "GET",
		data: {
			name,
			channeltype: "CampsiteGooddealChannel"
		},
		schema: [channels],
		onSuccess: setGooddealCampsiteChannels,
		onFailure: setChannelsFailure,
		label: FETCH_SEARCH_GOODDEAL_CAMPSITE_CHANNELS
	});
}

function setGooddealCampsiteChannels(normalized) {
	return {
		type: SET_GOODDEAL_CAMPSITE_CHANNELS,
		entities: normalized.entities
	};
}

export function fetchSearchGooddealNeighborhoodCityChannels(name) {
	return apiAction({
		url: "/channels/search",
		method: "GET",
		data: {
			name,
			channeltype: "CityGooddealChannel",
			appname: "HapiColibri"
		},
		schema: [channels],
		onSuccess: setGooddealNeighborhoodCityChannels,
		onFailure: setChannelsFailure,
		label: FETCH_SEARCH_GOODDEAL_NEIGHBORHOOD_CITY_CHANNELS
	});
}

function setGooddealNeighborhoodCityChannels(normalized) {
	return {
		type: SET_GOODDEAL_NEIGHBORHOOD_CITY_CHANNELS,
		entities: normalized.entities
	};
}

export function fetchSearchGooddealNeighborhoodChannels(name) {
	return apiAction({
		url: "/channels/search",
		method: "GET",
		data: {
			name,
			channeltype: "NeighborhoodGooddealChannel"
		},
		schema: [channels],
		onSuccess: setGooddealNeighborhoodChannels,
		onFailure: setChannelsFailure,
		label: FETCH_SEARCH_GOODDEAL_NEIGHBORHOOD_CHANNELS
	});
}

function setGooddealNeighborhoodChannels(normalized) {
	return {
		type: SET_GOODDEAL_NEIGHBORHOOD_CHANNELS,
		entities: normalized.entities
	};
}

export function fetchSearchEventCityChannels(name) {
	return apiAction({
		url: "/channels/search",
		method: "GET",
		data: {
			name,
			channeltype: "CityEventChannel",
			appname: "HapiCity"
		},
		schema: [channels],
		onSuccess: setEventCityChannels,
		onFailure: setChannelsFailure,
		label: FETCH_SEARCH_EVENT_CITY_CHANNELS
	});
}

function setEventCityChannels(normalized) {
	return {
		type: SET_EVENT_CITY_CHANNELS,
		entities: normalized.entities
	};
}

export function fetchSearchEventCampsiteCityChannels(name) {
	return apiAction({
		url: "/channels/search",
		method: "GET",
		data: {
			name,
			channeltype: "CityEventChannel",
			appname: "OCamping"
		},
		schema: [channels],
		onSuccess: setEventCampsiteCityChannels,
		onFailure: setChannelsFailure,
		label: FETCH_SEARCH_EVENT_CAMPSITE_CITY_CHANNELS
	});
}

function setEventCampsiteCityChannels(normalized) {
	return {
		type: SET_EVENT_CAMPSITE_CITY_CHANNELS,
		entities: normalized.entities
	};
}

export function fetchSearchEventCampsiteChannels(name) {
	return apiAction({
		url: "/channels/search",
		method: "GET",
		data: {
			name,
			channeltype: "CampsiteEventChannel"
		},
		schema: [channels],
		onSuccess: setEventCampsiteChannels,
		onFailure: setChannelsFailure,
		label: FETCH_SEARCH_EVENT_CAMPSITE_CHANNELS
	});
}

function setEventCampsiteChannels(normalized) {
	return {
		type: SET_EVENT_CAMPSITE_CHANNELS,
		entities: normalized.entities
	};
}

export function fetchSearchEventNeighborhoodCityChannels(name) {
	return apiAction({
		url: "/channels/search",
		method: "GET",
		data: {
			name,
			channeltype: "CityEventChannel",
			appname: "HapiColibri"
		},
		schema: [channels],
		onSuccess: setEventNeighborhoodCityChannels,
		onFailure: setChannelsFailure,
		label: FETCH_SEARCH_EVENT_NEIGHBORHOOD_CITY_CHANNELS
	});
}

function setEventNeighborhoodCityChannels(normalized) {
	return {
		type: SET_EVENT_NEIGHBORHOOD_CITY_CHANNELS,
		entities: normalized.entities
	};
}

export function fetchSearchEventNeighborhoodChannels(name) {
	return apiAction({
		url: "/channels/search",
		method: "GET",
		data: {
			name,
			channeltype: "NeighborhoodEventChannel"
		},
		schema: [channels],
		onSuccess: setEventNeighborhoodChannels,
		onFailure: setChannelsFailure,
		label: FETCH_SEARCH_EVENT_NEIGHBORHOOD_CHANNELS
	});
}

function setEventNeighborhoodChannels(normalized) {
	return {
		type: SET_EVENT_NEIGHBORHOOD_CHANNELS,
		entities: normalized.entities
	};
}

export function fetchGetGooddealChannels() {
	return apiAction({
		url: "/channels/gooddeal",
		method: "GET",
		schema: [channels],
		onSuccess: setGooddealChannels,
		onFailure: setGooddealChannelsFailure,
		label: FETCH_GET_GOODDEAL_CHANNELS
	});
}

function setGooddealChannels(normalized) {
	return {
		type: SET_GOODDEAL_CHANNELS,
		entities: normalized.entities
	};
}

function setGooddealChannelsFailure(data) {
	return {
		type: SET_GOODDEAL_CHANNELS_FAILURE,
		payload: data
	};
}

// export function fetchGetEventChannels() {
//   return apiAction({
//     url: "/channels/event",
//     method: "GET",
//     schema: [channels],
//     onSuccess: setEventChannels,
//     onFailure: setEventChannelsFailure,
//     label: FETCH_GET_EVENT_CHANNELS
//   });
// }

// function setEventChannels(normalized) {
//   return {
//     type: SET_EVENT_CHANNELS,
//     entities: normalized.entities
//   };
// }

// function setEventChannelsFailure(data) {
//   return {
//     type: SET_EVENT_CHANNELS_FAILURE,
//     payload: data
//   };
// }
