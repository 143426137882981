// @material-ui/core components
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Checkbox from "@material-ui/core/Checkbox";
import InputLabel from "@material-ui/core/InputLabel";
import withStyles from "@material-ui/core/styles/withStyles";
import FormHelperText from "@material-ui/core/FormHelperText";
import CustomInput from "components/CustomInput/CustomInput";

import Activity from "components/DigiHapi/Activity";

// @material-ui/icons
import Check from "@material-ui/icons/Check";
import { fetchGetTemplatesCategoriesIds } from "actions/template";
import {
	SET_TEMPLATE_CATEGORY,
	SET_TEMPLATE_NEW_CATEGORY,
	TOGGLE_ACCEPT_NOTIF,
	REMOVE_TEMPLATE_IMAGE,
	SET_TEMPLATE_FILES_IMAGES,
	SET_TEMPLATE_IMAGE_ERROR,
	SET_TEMPLATE_NAME_FR,
	SET_TEMPLATE_NAME_ERROR,
	SET_TEMPLATE_PICTO,
	TYPE_TEMPLATE,
	TYPE_ACTIVITY
} from "actions/types";
import customSelectStyle from "assets/jss/material-dashboard-pro-react/customSelectStyle";
import customInputStyle from "assets/jss/material-dashboard-pro-react/components/customInputStyle";
import regularFormsStyle from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import ImageUpload from "components/CustomUpload/ImageUpload";
// core components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import PropTypes from "prop-types";
import { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

const style = {
	infoText: {
		fontWeight: "300",
		margin: "10px 0 30px",
		textAlign: "center"
	},
	inputAdornmentIcon: {
		color: "#555"
	},
	inputAdornment: {
		position: "relative"
	},
	...customInputStyle,
	...customSelectStyle,
	...regularFormsStyle
};

class Step1 extends Component {
	componentDidMount() {
		const { fetchGetTemplatesCategoriesIds, type, system, community } =
			this.props;
		fetchGetTemplatesCategoriesIds(type, system, community._id);
	}

	sendState() {
		return this.state;
	}

	isValidated() {
		const { dispatch, files, photoUri, name, type } = this.props;

		let success = true;
		if (files.length === 0 && !photoUri && type === "Template") {
			dispatch({
				type: SET_TEMPLATE_IMAGE_ERROR
			});
			success = false;
		}
		if (!name) {
			dispatch({
				type: SET_TEMPLATE_NAME_ERROR
			});
			success = false;
		}

		return success;
	}

	onImageLoaded = (name, imagePreviewUrl) => {
		const { dispatch } = this.props;
		dispatch({
			type: SET_TEMPLATE_FILES_IMAGES,
			payload: [{ name, base64: imagePreviewUrl }]
		});
	};

	onImageRemoved = () => {
		this.props.dispatch({
			type: REMOVE_TEMPLATE_IMAGE
		});
	};

	renderMenuItems = data => {
		const { classes } = this.props;
		const components = [];

		// eslint-disable-next-line
		for (const obj of data) {
			components.push(
				<MenuItem
					key={obj._id}
					classes={{
						root: classes.selectMenuItem,
						selected: classes.selectMenuItemSelected
					}}
					value={obj._id}
				>
					{obj.name}
				</MenuItem>
			);
		}
		return components;
	};

	renderPictoItems = () => {
		const { classes, pictos } = this.props;
		const components = [];

		// eslint-disable-next-line
		for (const [key, value] of Object.entries(pictos)) {
			components.push(
				<MenuItem
					key={key}
					classes={{
						root: classes.selectMenuItem,
						selected: classes.selectMenuItemSelected
					}}
					value={key}
				>
					<img
						className={classes.image}
						alt={`${key} made by Freepik from www.flaticon.com`}
						src={value}
					/>
				</MenuItem>
			);
		}
		return components;
	};

	render() {
		const {
			classes,
			dispatch,
			_id,
			imageErrorMsg,
			categories,
			categoryId,
			newCategoryId,
			photoUri,
			acceptNotif,
			name,
			nameErrorMsg,
			categoryErrorMsg,
			picto,
			type,
			isFetching
		} = this.props;
		const category = _id ? newCategoryId : categoryId;
		return isFetching ? (
			<Activity />
		) : (
			<GridContainer justifyContent="center">
				{type === "Template" && (
					<GridItem xs={12} sm={4} md={4}>
						<legend>Image *</legend>
						<ImageUpload
							ref={this.child}
							onImageLoaded={this.onImageLoaded}
							onImageRemoved={this.onImageRemoved}
							photoUri={photoUri}
							addButtonProps={{
								color: "brown",
								round: true
							}}
							changeButtonProps={{
								color: "brown",
								round: true
							}}
							removeButtonProps={{
								color: "danger",
								round: true
							}}
						/>
						{imageErrorMsg && (
							<FormHelperText className={classes.labelRootError}>
								{imageErrorMsg}
							</FormHelperText>
						)}
					</GridItem>
				)}

				<GridItem xs={12} sm={12} md={12}>
					{(type === "Activity" || type === "Proposal") && (
						<FormControl fullWidth className={classes.selectFormControl}>
							<InputLabel
								htmlFor="simple-select"
								className={classes.selectLabel}
							>
								Sélectionner le pictogramme *
							</InputLabel>
							<Select
								MenuProps={{
									className: classes.selectMenu
								}}
								classes={{
									select: classes.select
								}}
								value={picto ? picto : ""}
								onChange={e =>
									dispatch({
										type: SET_TEMPLATE_PICTO,
										payload: e.target.value
									})
								}
								inputProps={{
									required: true,
									name: "simpleSelect",
									id: "simple-select"
								}}
							>
								<MenuItem
									disabled
									classes={{
										root: classes.selectMenuItem
									}}
								>
									Pictogramme *
								</MenuItem>
								{this.renderPictoItems()}
							</Select>
						</FormControl>
					)}
					<FormControl fullWidth className={classes.selectFormControl}>
						<InputLabel htmlFor="simple-select" className={classes.selectLabel}>
							Sélectionner la catégorie
						</InputLabel>
						<Select
							MenuProps={{
								className: classes.selectMenu
							}}
							classes={{
								select: classes.select
							}}
							value={category ? category : ""}
							onChange={e =>
								_id
									? dispatch({
											type: SET_TEMPLATE_NEW_CATEGORY,
											categoryId: e.target.value
									  })
									: dispatch({
											type: SET_TEMPLATE_CATEGORY,
											categoryId: e.target.value
									  })
							}
							inputProps={{
								required: true,
								name: "simpleSelect",
								id: "simple-select"
							}}
						>
							<MenuItem
								disabled
								classes={{
									root: classes.selectMenuItem
								}}
							>
								Catégorie *
							</MenuItem>
							{this.renderMenuItems(categories)}
						</Select>
					</FormControl>
					{categoryErrorMsg && (
						<FormHelperText className={classes.labelRootError}>
							{categoryErrorMsg}
						</FormHelperText>
					)}
				</GridItem>
				<GridItem xs={12} sm={12} md={12}>
					<CustomInput
						labelText="Nom *"
						id="name-fr"
						formControlProps={{
							fullWidth: true
						}}
						error={nameErrorMsg !== null}
						helperText={nameErrorMsg}
						inputProps={{
							required: true,
							value: name ? name : "",
							onChange: e =>
								dispatch({
									type: SET_TEMPLATE_NAME_FR,
									payload: e.target.value
								})
						}}
					/>
				</GridItem>
				<GridItem xs={12} sm={12} md={12}>
					<div className={classes.checkboxAndRadio}>
						<FormControlLabel
							control={
								<Checkbox
									checked={acceptNotif}
									tabIndex={-1}
									onClick={() =>
										dispatch({
											type: TOGGLE_ACCEPT_NOTIF
										})
									}
									checkedIcon={<Check className={classes.checkedIcon} />}
									icon={<Check className={classes.uncheckedIcon} />}
									classes={{
										checked: classes.checked,
										root: classes.checkRoot
									}}
								/>
							}
							classes={{
								label: classes.label,
								root: classes.labelRoot
							}}
							label="Accepter les alertes"
						/>
					</div>
				</GridItem>
			</GridContainer>
		);
	}
}

Step1.propTypes = {
	classes: PropTypes.object
};

const mapStateToProps = state => {
	const {
		addTemplateResult,
		_id,
		categoryId,
		newCategoryId,
		name,
		description,
		photoUri,
		files,
		lang,
		dates,
		date,
		times,
		time,
		equipments,
		equipment,
		order,
		acceptNotif,
		imageErrorMsg,
		nameErrorMsg,
		categoryErrorMsg,
		picto
	} = state.templateReducer;

	const { pictos } = state.uploadReducer;

	const {
		isFetching,
		type,
		system,
		templatesCategoriesNames,
		activitiesCategoriesNames,
		proposalsCategoriesNames,
		systemTemplatesCategoriesNames,
		systemActivitiesCategoriesNames,
		systemProposalsCategoriesNames
	} = state.templateCategoryReducer;

	const { community } = state.entitiesReducer;

	return {
		isFetching,
		addTemplateResult,
		_id,
		categoryId,
		newCategoryId,
		name,
		description,
		photoUri,
		files,
		lang,
		categories:
			type === TYPE_TEMPLATE
				? system
					? systemTemplatesCategoriesNames
					: templatesCategoriesNames
				: type === TYPE_ACTIVITY
				? system
					? systemActivitiesCategoriesNames
					: activitiesCategoriesNames
				: system
				? systemProposalsCategoriesNames
				: proposalsCategoriesNames,
		dates,
		date,
		times,
		time,
		equipments,
		equipment,
		order,
		acceptNotif,
		imageErrorMsg,
		nameErrorMsg,
		categoryErrorMsg,
		picto,
		pictos,
		type,
		system,
		community
	};
};

const mapDispatchToProps = dispatch => {
	let actions = bindActionCreators(
		{
			fetchGetTemplatesCategoriesIds
		},
		dispatch
	);
	return { ...actions, dispatch };
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withStyles(style)(Step1));
