/*eslint-disable*/
import PropTypes from "prop-types";
import cx from "classnames";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import styles from "assets/jss/material-dashboard-pro-react/components/footerStyle.js";

const useStyles = makeStyles(styles);

export default function Footer(props) {
	const classes = useStyles();

	const { fluid, white } = props;
	var container = cx({
		[classes.container]: !fluid,
		[classes.containerFluid]: fluid,
		[classes.whiteColor]: white
	});
	var anchor =
		classes.a +
		cx({
			[" " + classes.whiteColor]: white
		});
	return (
		<footer className={classes.footer}>
			<div className={container}>
				<p className={classes.right}>
					&copy; {1900 + new Date().getYear()}{" "}
					<a href="https://www.digihapi.com" className={anchor} target="_blank">
						DigiHapi
					</a>
					{", made by humans for humans"}
				</p>
			</div>
		</footer>
	);
}

Footer.propTypes = {
	fluid: PropTypes.bool,
	white: PropTypes.bool,
	rtlActive: PropTypes.bool
};
